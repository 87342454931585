import React, { useEffect } from "react"
import { TelesatItemStyles } from "./TelesatItem.styles"
import Leo from "../../../svgs/leo-network.svg"
import DashedLine from "../../UI/DashedLine"
import { Parallax } from "react-scroll-parallax"

import CountUp from "react-countup"
import { useInView } from "react-intersection-observer"

import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

import UnderlineLeft from "../../../svgs/underline-left.svg"
import UnderlineRight from "../../../svgs/underline-right.svg"

gsap.registerPlugin(ScrollTrigger)

const TelesatItem = () => {
 
  useEffect(() => {
    ScrollTrigger.batch(".upper-text", {
      start: "100px bottom",
      onEnter: (elements, element, triggers) => {
        const tl = gsap.timeline()
        tl.to(elements, {
          opacity: 1,
          y: 0,
          duration: 1,
          stagger: {
            each: .5,
            onComplete() {
              gsap.fromTo(
                this._targets[0].parentNode.lastChild,
                {
                  opacity: 0,
                },
                { opacity: 1, duration: 2 }
              )
            },
          },
        })
      },
    })
  }, [])

  const COUNT_UP_START = 0

  const { ref, inView, entry } = useInView({
    threshold: 0,
    triggerOnce: true,
  })

  return (
    <TelesatItemStyles>
      <DashedLine height="270" length="150" v="450" m="150" />
      <div className="grid">
        <div className="left">
          <div className="item">
            <h2 className="upper-text" ref={ref}>
              <CountUp
                start={inView ? COUNT_UP_START : 298}
                end={4000}
                delay={0}
                duration={5}
                suffix="+"
              > 
                {({ countUpRef }) => (
                  <span className="number" ref={countUpRef} />
                )}
              </CountUp>
              satellites in LEO constellation
            </h2>
            <UnderlineLeft />
            <div></div>
          </div>
          <div className="item">
            <p className="upper-text">
              Approximately <span className="">600 miles (1,000km)</span> from
              earth
            </p>
            <UnderlineLeft />
            <div></div>
          </div>
          <div className="item">
            <h2 className="upper-text">Speed of light</h2>
            <UnderlineLeft />
            <p className="lower-text">
              Data can travel at the speed of light from one satellite to
              another resulting in a full interconnected global mesh network.
            </p>
          </div>
        </div>
        <Leo />
        <div className="right">
          <div className="item">
            <h2 className="upper-text">20x faster</h2>
            <UnderlineRight />
            <p className="lower-text">than today's GEO satellites and on par with fiber networks.</p>
          </div>
          <div className="item">
            <h2 className="upper-text">Airconnect Ka IFC</h2>
            <UnderlineRight />
            <p className="lower-text">
              Airconnect Ka IFC terminal to be used with the Telesat Lightspeed.
            </p>
          </div>
        </div>
      </div>
      <Parallax y={[20, -25]}>
        <span className="parallax beyond">Beyond</span>
      </Parallax>
      <DashedLine height="410" length="410" v="450" m="150" />
    </TelesatItemStyles>
  )
}

export default TelesatItem
