import styled, { keyframes } from "styled-components"
import Rems from "../../styles/mixins/Rems"

const ChevronFloat = keyframes`
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0);
  }
`

// NEGATIVE MARGINS!? You devil you!
// Previously were set :
// margin-top: -225px;
// @media screen and (min-width: 768px) margin-top: -390px;
export const TimelineStyles = styled.section`
  padding: 50px 0 50px;

  margin-top: -600px;
  color: white;
  z-index: 20;
  position: relative;

  background: rgb(229, 181, 181);
  background: linear-gradient(
    180deg,
    rgba(229, 181, 181, 0) 0%,
    rgba(58, 95, 121, 1) 9%,
    rgba(22, 30, 34) 30%,
    rgba(22, 30, 34) 50%,
    rgba(36, 53, 66, 1) 60%,
    rgba(37, 64, 84, 1) 80%,
    rgba(29, 34, 36, 1) 100%
  );

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  .white-title {
    text-transform: uppercase;
    margin: calc(var(--spacing) * 3) auto calc(var(--spacing) * 3);
    ${Rems({ type: "font-size", size: 28 })};
    font-family: var(--font-main);
    letter-spacing: 2px;
  }

  .star-clouds {
    position: relative;
    margin-top:-20vh;
    .gatsby-image-wrapper {
      z-index: -1;
      position: absolute !important;
      top: -250px;
    }

    .left-cloud {
      left: 0;
      max-width: 650px;
    }
    .right-cloud {
      right: 0;
      max-height: 1000px;
    }
  }

  .milky-way {
    position: relative;
    width: 100vw;
    .gatsby-image-wrapper {
      position: absolute !important; // overwrite default
      right: 0;
      top: -540px;
      width: 130%;
      max-width: 130%;
      max-height: 950px;
      z-index: -1;
    }
  }

  .timeline-end {
    max-width: var(--content-width);
    margin-left: auto;
    margin-right: auto;
    margin-top:-40vh;
    svg {
      margin: calc(var(--spacing) * 3);
    }
  }

  .entry {
    margin-bottom: var(--spacing);
  }

  .chevron {
    color: var(--neon);
    border: 2px solid var(--neon);
    width: 35px;
    height: 35px;
    padding: 5px;
    border-radius: 50%;
    box-shadow: 0 0 8px 0.5px var(--neon);

    animation: ${ChevronFloat} 4s ease-in-out infinite;
    transition: all 4s ease-in-out;
  }

  .white-glow {
    ${Rems({ type: "font-size", size: 36 })};
    letter-spacing: 2px;
    font-family: var(--font-main);

    font-weight: lighter;
    filter: drop-shadow(0 0px 5px rgba(96, 240, 235, 0.7));
  }

  .start-timeline {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column nowrap;
    position: relative;
  }

  .cross {
    ${Rems({ type: "font-size", size: 24 })};
    margin-bottom: calc(var(--spacing) / 2);
  }

  .neon-title {
    text-transform: uppercase;
    color: var(--neon);
    font-family: var(--font-main);
    ${Rems({ type: "font-size", size: 22 })};
    letter-spacing: 3px;
    filter: drop-shadow(0 0px 5px rgba(96, 240, 235, 0.5));
  }

  .white-title,
  .start-timeline,
  .timeline-end {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }


  @media screen and (min-width: 768px) {

    .star-clouds {
      margin-top:0;
    }

    margin-top: -520px;
    padding: 250px 0 0px;

    background: linear-gradient(
      180deg,
      rgba(192, 181, 179, 0) 0%,
      rgba(192, 181, 179, 0.3) 3%,
      rgba(58, 95, 121, 1) 8%,
      rgba(22, 30, 34) 18%,
      rgba(22, 30, 34) 50%,
      rgba(36, 53, 66, 1) 60%,
      rgba(37, 64, 84, 1) 80%,
      rgba(29, 34, 36, 1) 100%
    );

    .neon-title,
    .white-title {
      ${Rems({ type: "font-size", size: 34 })};
    }

    .white-glow {
      ${Rems({ type: "font-size", size: 46 })};
    }

    .milky-way {
      margin-top: -250px;
    }
    .timeline-end {
      margin-top: -320px;
    }

    .timeline-spacer {
      margin-top: -100px;
    }
  }
`
