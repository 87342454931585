import React, { useState } from "react"
import SwiperCore, { Pagination, A11y, EffectFade, Autoplay } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import { SwiperStyles } from "./SwiperStyles"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import "swiper/swiper.scss"
import "swiper/components/effect-fade/effect-fade.scss"

import { graphql, useStaticQuery } from "gatsby"

SwiperCore.use([Pagination, A11y, EffectFade, Autoplay])

const SwiperComponent = () => {
  const paginationState = useState([
    {
      text: "",
    },
    {
      text: 'Today, mobility markets are fighting for bandwidth with people on land which makes "high-speed" connectivity seem out of reach.',
    },
    {
      text: "The Anuvu Constellation will provide two high-throughput satellites, with six more to follow, committed to those in the air and at sea.",
    },
    {
      text: "This continues the expansion of Anuvu's layered capacity model and will lay the groundwork for a global hybrid network that will include GEO, LEO, and MicroGEO capacity.",
    },
  ])

  const { tableOne, tableTwo, tableThree } = useStaticQuery(graphql`
    query {
      tableOne: file(relativePath: { eq: "table-1.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, formats: [AUTO, WEBP])
        }
      }
      tableTwo: file(relativePath: { eq: "table-2.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, formats: [AUTO, WEBP])
        }
      }
      tableThree: file(relativePath: { eq: "table-3.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, formats: [AUTO, WEBP])
        }
      }
    }
  `)

  const tableImageOne = getImage(tableOne)
  const tableImageTwo = getImage(tableTwo)
  const tableImageThree = getImage(tableThree)

  return (
    <SwiperStyles>
      <Swiper
        pagination={{
          clickable: true,
          renderBullet: function (index, className) {
            return (
              '<span class="' +
              className +
              '">' +
              paginationState[0][index + 1].text +
              "</span>"
            )
          },
        }}
        effect="fade"
        centeredSlides="true"
        fadeEffect={{ crossFade: true }}
        slidesPerView={1}
        direction="horizontal"
        onSlideChange={() => {} }
        autoplay={{ delay: 2500, disableOnInteraction: false }}
        infinite="true"
        gap="30"
        // Check that this is infinite on firefox + delay: 3000^
      >
        <SwiperSlide>
          <GatsbyImage
            image={tableImageOne}
            alt="Anuvu Constellation Diagram One"
            className="diagram"
            objectFit="contain"
            objectPosition="bottom"
          />
        </SwiperSlide>
        <SwiperSlide>
          <GatsbyImage
            image={tableImageTwo}
            alt="Anuvu Constellation Diagram Two"
            className="diagram"
          />
        </SwiperSlide>
        <SwiperSlide>
          <GatsbyImage
            image={tableImageThree}
            alt="Anuvu Constellation Diagram Three"
            className="diagram"
          />
        </SwiperSlide>
      </Swiper>
    </SwiperStyles>
  )
}

export default SwiperComponent
