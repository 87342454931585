import React, { useEffect } from "react"

import { PlaneStyles } from "./PlaneSection.styles"
import { StaticImage } from "gatsby-plugin-image"

const percentageVisible = (element) => {
  // Get the relevant measurements and positions
  const viewportHeight = window.innerHeight
  const scrollTop = window.scrollY
  const elementOffsetTop = element.offsetTop
  const elementHeight = element.offsetHeight

  // Calculate percentage of the element that's been seen
  const distance = scrollTop + viewportHeight - elementOffsetTop
  const percentage = Math.round(
    distance / ((viewportHeight + elementHeight) / 100)
  )
  return percentage / 100
}

const PlaneSection = () => {
  useEffect(() => {
    
    const circle = document.getElementById("smallCirc") // small circ
    const svg = document.getElementById("arcSvg")
    const myline = svg.querySelector("path")
   
    const pathLength = myline.getTotalLength()

    // element to trigger scroll animation
    const elementToTrigger = svg.parentNode.parentNode

    function handleCircleAnim( percentage ) {
    
      // Length to offset the dashes
      const draw = pathLength * percentage

      // Reverse the drawing (when scrolling upwards)
      myline.style.strokeDashoffset = pathLength - draw

      // get point at length
      const endPoint = myline.getPointAtLength( draw )
      circle.setAttribute("cx", endPoint.x)
      circle.setAttribute("cy", endPoint.y)
    }
    
    // this updates the coords
    function resetCircleAnim(){
      // What % down is it?
      // const documentScrollpercent = (document.body.scrollTop + document.documentElement.scrollTop) / (document.documentElement.scrollHeight - document.documentElement.clientHeight)
      requestAnimationFrame( ()=>{
        // SVG scroll percent...
        const percentageInView = percentageVisible(elementToTrigger)
        if (percentageInView >= 0 && percentageInView <= 1)
        {
          handleCircleAnim(percentageInView)
        }
      })
    }

    window.addEventListener("scroll", resetCircleAnim)
   
    return ()=>{
      
      window.removeEventListener("scroll", resetCircleAnim )
    }
  }, [])

  return (
    <PlaneStyles>
      <StaticImage
        src="../../images/plane.png"
        alt="Plane"
        className="big-plane"
        id="plane"
        objectFit="contain"
      />
      <StaticImage
        src="../../images/sky-no-plane.png"
        alt="sky"
        objectFit="contain"
        objectPosition="top right"
        className="sky-background"
      />
      <div className="svg-container">
        <svg
          id="arcSvg"
          width="800"
          height="400"
          viewBox="0 0 800 400" 
          preserveAspectRatio="xMidYMid meet"
        >
          <path
            // M start p-start Q [half-svg-width] [height?] totalwidth p-end
            // M 0 400 Q ${clientWidth / 2} -50 ${clientWidth} 400
            d="M 
                0 400 
               Q 
                400 -50
                800 400"
            fill="none"
            strokeWidth="2"
            stroke="#7cf0ec"
          />
          <circle
            id="smallCirc"
            cx="100"
            cy="400"
            r="20"
            stroke="#7cf0ec"
            fill="none"
            strokeWidth="2"
          />
        </svg>
      </div>
    </PlaneStyles>
  )
}

export default PlaneSection
