import styled from "styled-components"
import Rems from "../../styles/mixins/Rems"

export const TelesatStyled = styled.section`
  background: linear-gradient(
    180deg,
    rgba(29, 34, 36, 1) 0%,
    rgba(29, 34, 36, 1) 80%,
    rgba(29, 34, 36, 0) 100%
  );
  padding: 50px 0px;
  z-index: 10;
  position: relative;

  .container {
    max-width: var(--content-width);
    margin-left: auto;
    margin-right: auto;
  }

  .leo-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column nowrap;
    margin-top: -100px;
    margin-bottom: -50px;
    svg {
      min-width: 150%;
      max-width: 400px;
      padding-right:33px;
    }
  }

  .neon-line {
  }

  svg.neon-line {
    width: 100%;
  }

  .info-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column nowrap;
    text-align: center;
    color: var(--white);
    overflow: hidden;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    max-width: var(--content-width);
    h2 {
      max-width: 775px;
      margin-bottom: 0;
      padding-bottom: calc(var(--spacing) * 3);
      position: relative;
      letter-spacing: 2px;
    }

    p {
      max-width: 900px;
      margin: calc(var(--spacing) * 3) 0;
    }
  }

  @media screen and (min-width: 768px) {
    .info-container {
      p {
        margin: calc(var(--spacing) * 4) 0;
      }
    }
  }

  @media screen and (min-width: 1024px) {
    .leo-container svg {
      min-width: 170%;
      max-width: 875px;
    }

    .info-container h2 {
      ${Rems({ type: "font-size", size: 40 })};
    }
  }
`
