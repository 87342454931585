import styled from "styled-components"

export const SwiperStyles = styled.div`
  .swiper-container {
    display: grid;
    width: 100%;
    margin: calc(var(--spacing) * 5) auto calc(var(--spacing) * 3);
    cursor: pointer;
    border-right: 1px solid #fff;
    .swiper-slide {
      border-top: 1px solid #fff;
      border-bottom: 1px solid #fff;
      img {
        max-width: 950px;
        width: 100%;
        height: 100%;
        max-height: 820px;
      }
    }
    .swiper-pagination {
      position: unset;
      z-index: 10;
      display: grid;
      span {
        width: 100%;
        max-width: calc(100vw - 3%);
        height: 100%;
        border: 1px solid #fff;
        padding: calc(var(--spacing) * 1.5);
        font-family: var(--font-titles);
        background: #517692;
        transition: all 0.3s ease-in-out;
        &:hover,
        &.swiper-pagination-bullet-active {
          color: var(--neon);
          background: var(--navy);
          border: 1px solid var(--neon);
        }
      }
    }
  }

  @media screen and (min-width: 768px) {
    .swiper-pagination span {
      padding: calc(var(--spacing) * 2);
    }
  }

  @media screen and (min-width: 1024px) {
    .swiper-container {
      grid-template-columns: 25% 75%;
    }    
  }
`
