import React, { useRef, useEffect } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { HeaderStyles } from "./Header.styles"
import { getImage, GatsbyImage, StaticImage } from "gatsby-plugin-image"

import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import StatusTimelineSection from "../StatusTimelineSection/StatusTimelineSection"

gsap.registerPlugin(ScrollTrigger)

const HeaderHero = ({ siteTitle }) => {
  const { background } = useStaticQuery(
    graphql`
      query {
        background: file(relativePath: { eq: "hero-test.png" }) {
          childImageSharp {
            gatsbyImageData(quality: 60, formats: [AUTO, WEBP])
          }
        }
      }
    `
  )

  const image = getImage(background)
  const ref = useRef(null)

  useEffect(() => {
    // const element = ref.current
    // gsap.fromTo(
    //   element.querySelector(".text-container"),
    //   {
    //     y: 0,
    //   },
    //   {
    //     y: -60,
    //     scrollTrigger: {
    //       trigger: element.querySelector(".hero"),
    //       start: "top top",
    //       end: "+=700",
    //       scrub: true,
    //     },
    //   }
    // )
    // this is causing issues on iphone
  }, [])

  const handleMove = () => {
    const footerEle = document.querySelector("footer")
    footerEle.scrollIntoView({ behavior: "smooth" })
  }

  return (
    <HeaderStyles className="hero" ref={ref}>
      <GatsbyImage
        image={image}
        alt={"Background"}
        className="background"
        backgroundColor="transparent"
      />
      <div className="text-container">
        <OutboundLink href="https://www.anuvu.com/" target="_blank" rel="noreferrer" aria-label="Click here to visit the Anuvu Website" title="Click here to visit the Anuvu Website">
          <StaticImage
            src="../../images/anuvu-logo.png"
            alt="The Anuvu Constellation Website"
            className="logo"
            backgroundColor="transparent"
          />
        </OutboundLink>
        <h1>A new way to look at the business of satellite connectivity</h1>
        <StatusTimelineSection />

     
      </div>   
      <a className="cta btn neon" href="#contact-form">
        Be in the know
      </a>
    </HeaderStyles>
  )
}

HeaderHero.propTypes = {
  siteTitle: PropTypes.string,
}

HeaderHero.defaultProps = {
  siteTitle: ``,
}

export default HeaderHero
