import React, { useEffect, useRef } from "react"
import { TelesatStyled } from "./TelesatSection.styles"

import Telesat from "../../svgs/telesat-light.svg"

import NeonLine from "../../svgs/neon-line.svg"

import { gsap } from "gsap"
import { ScrollTrigger, CSSPlugin } from "gsap/all"

gsap.registerPlugin(ScrollTrigger, CSSPlugin)

const TelesatSection = () => {
  const container = useRef()

  useEffect(() => {
    gsap.fromTo(
      ".info-container .neon-line #lineNeon",
      {
        duration: 8,
        attr: {
          d: "M -100 0 l 180 0",
        },
      },
      {
        attr: {
          d: "M 700 0 l 180 0",
        },
        scrollTrigger: {
          trigger: container.current,
          scrub: true,
          start: "0px bottom",
          toggleActions: "restart none none none",
        },
      }
    )
  }, [])

  const handleMove = () => {
    const footerEle = document.querySelector("footer")
    footerEle.scrollIntoView({ behavior: "smooth" })
  }

  return (
    <TelesatStyled>
      <div className="container">
        <div className="leo-container">
          <Telesat />
        </div>
        <div className="info-container" ref={container}>
          <h2>It's clear the satellite world needs to move faster.</h2>
          <NeonLine className="neon-line" />
          <p>
            Our fully-managed, vertically integrated, rapidly scalable solution
            is the revolution needed to keep pace with customer needs. With us,
            you'll be at the cutting-edge of connectivity business models,
            mixing performance, speed and economics, today and into the future.
          </p>

          <button type="button" className="cta btn neon" onClick={() => handleMove()}>
            Be in the know
          </button>
        </div>
      </div>
    </TelesatStyled>
  )
}

export default TelesatSection
