import styled from "styled-components"

// there are issues with this overlay
// so to fix them we require more padding and
// clamps to prevent too much  / too little

export const PlaneStyles = styled.section`
 
  --padding-top:200px;

  background: linear-gradient(180deg, #5378a4 0%, rgba(103, 143, 182, 1) 80%, rgba(103, 143, 182, 1) 100%);

  margin-top: -300px;
  position: relative;

  padding: var(--padding-top) 0 50px;
  color: white;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  .big-plane {
    z-index: 30;
    min-height: 750px;
    max-width: 450px;
    position: absolute;
  }

  .sky-background {
    max-height: 1200px;
  }

  div.svg-container {
    position: absolute;
    bottom:0;
    left:0;
    right:0;
    display: block;
    z-index:19;
    margin:0;
    svg {
      --y:max(-150px, -75%);
      --scale:1.5;
      --circle:20;
      display:block;
      filter: drop-shadow(0 0 4px var(--neon));
      max-width:100%;
      margin-left:auto;
      margin-right:auto;
      height:auto;
      transform: scale( var(--scale) ) translateY( var(--y,0) );
      transform-origin: center;

      width:100%;
   
    }
  }
  

  @media screen and (min-width: 768px) {
    --padding-top:200px;
    
    div.svg-container svg {
      --y:max(-150px, -62%);
      --circle:21;
    }

    .big-plane {
      max-width: 500px;
      left: 8%;
    }
  }

  @media screen and (min-width: 1024px) {
    margin-top: -420px;

    div.svg-container svg {
      --y:max(-210px,-42%);
      --circle:22;
    }

    .big-plane {
      max-width: 650px;
      left: 19%;
    }
  }


  @media screen and (min-width: 1480px) {

    div.svg-container svg {
      --y:max(-300px, -150%);
      --circle:24;
    }
  
    .big-plane {
      max-width: 650px;
      left: 19%;
    }
  }
`
