import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Rems from "../../styles/mixins/Rems"

const ButtonStyles = styled.button`
  padding: 10px 30px;
  text-transform: uppercase;
  text-decoration: none;
  text-shadow: unset;
  box-shadow: unset;
  letter-spacing: 4px;
  border-radius: 30px;
  ${Rems({ type: "font-size", size: 16 })};
  font-family: var(--font-bold-titles);
  text-align: center;
  display: inline-block;
  width: fit-content;
  font-weight: 600;

  transition: all 0.3s ease-in-out;
  cursor: pointer;

  &.neon-navy {
    background: var(--navy);
    color: var(--neon);
    border: 1px solid var(--neon);
    box-shadow: 0 0 5px 1px var(--neon);

    &:hover,
    &:active,
    &:focus {
      color: var(--navy);
      background: var(--neon);
      transform: scale(1.03);
    }
  }
`

const Button = ({ type, color, children }) => {
  return (
    <ButtonStyles type={type} className={`cta btn ${color}`}>
      {children}
    </ButtonStyles>
  )
}

export default Button
