import React, { useState } from "react"
import addToMailchimp from "gatsby-plugin-mailchimp"
import { checkValidity, updateObject } from "../../shared/utility"
import Input from "../UI/Input"
import { FooterStyles } from "./Footer.styles"
import {
  FaLinkedin,
  FaTwitter,
  FaYoutube,
  FaChevronRight,
} from "react-icons/fa"
import Button from "../UI/Button"
import { OutboundLink } from "gatsby-plugin-google-gtag"

const Footer = () => {
  const [form, setForm] = useState({
    first_name: {
      elementType: "input",
      elementConfig: {
        type: "text",
        label: "First Name",
        name: "first_name",
      },
      value: "",
      validation: {
        required: true,
        isEmail: false,
      },
      valid: false,
      touched: false,
    },
    last_name: {
      elementType: "input",
      elementConfig: {
        type: "text",
        label: "Last Name",
        name: "last_name",
      },
      value: "",
      validation: {
        required: true,
        isEmail: false,
      },
      valid: false,
      touched: false,
    },
    email: {
      elementType: "input",
      elementConfig: {
        type: "email",
        label: "Email Address",
        name: "email",
      },
      value: "",
      validation: {
        required: true,
        isEmail: true,
      },
      valid: false,
      touched: false,
    },
    company: {
      elementType: "input",
      elementConfig: {
        type: "text",
        label: "Company",
        name: "company",
      },
      value: "",
      validation: {
        required: true,
        isEmail: false,
      },
      valid: false,
      touched: false,
    },
    industry_aviation: {
      elementTitle: "Industry",
      elementType: "checkbox",
      elementConfig: {
        type: "checkbox",
        label: "Aviation",
        name: "aviation",
      },
      value: 1,
      validation: {
        required: false,
        isEmail: false,
      },
      valid: false,
      touched: false,
    },
    industry_maritime: {
      elementType: "checkbox",
      elementConfig: {
        type: "checkbox",
        label: "Maritime",
        name: "maritime",
      },
      value: 2,
      validation: {
        required: false,
        isEmail: false,
      },
      valid: false,
      touched: false,
    },
    // added by zen for Kim Ruvolo
    // addition of Energy / Government / Partner
    industry_energy: {
      elementType: "checkbox",
      elementConfig: {
        type: "checkbox",
        label: "Energy",
        name: "energy",
      },
      // FIXME: 
      value: 3,
      validation: {
        required: false,
        isEmail: false,
      },
      valid: false,
      touched: false,
    },
    industry_government: {
      elementType: "checkbox",
      elementConfig: {
        type: "checkbox",
        label: "Government",
        name: "government",
      },
      // FIXME: 
      value: 3,
      validation: {
        required: false,
        isEmail: false,
      },
      valid: false,
      touched: false,
    },
    industry_partner: {
      elementType: "checkbox",
      elementConfig: {
        type: "checkbox",
        label: "Partner",
        name: "partner",
      },
      // FIXME: 
      value: 3,
      validation: {
        required: false,
        isEmail: false,
      },
      valid: false,
      touched: false,
    },



    // ---
    industry_other: {
      elementType: "checkbox",
      elementConfig: {
        type: "checkbox",
        label: "Other",
        name: "other",
      },
      value: 4,
      validation: {
        required: false,
        isEmail: false,
      },
      valid: false,
      touched: false,
    },
  })
  const [formIsValid, setFormIsValid] = useState(false)

  const [message, setMessage] = useState({})

  const inputChangedHandler = (event, inputIdentifier) => {
    const updatedFormElement = updateObject(form[inputIdentifier], {
      value: event.target.value,
      valid: checkValidity(
        event.target.value,
        form[inputIdentifier].validation
      ),
      touched: !form[inputIdentifier].touched,
    })

    const updatedForm = updateObject(form, {
      [inputIdentifier]: updatedFormElement,
    })

    let formIsValid = true
    for (let inputIdentifier in updatedForm) {
      formIsValid = updatedForm[inputIdentifier].valid && formIsValid
    }
    setForm(updatedForm)
    setFormIsValid(formIsValid)
  }

  const formElementsArray = []
  for (let key in form) {
    formElementsArray.push({
      id: key,
      config: form[key],
    })
  }

  // Mailchimp
  // This still needs to be properly hooked up
  // I have installed the plugin but just needs to have the data tested and pushed though
  // TODO: Check the mailchimp signup on the existing homepage.
  const handleSubmit = (
    event,
    email,
    fname,
    lname,
    company,
    aviation,
    maritime,
    energy,
    government,
    partner,
    other,
    formIsValid
  ) => {
    let listFields = {
      FNAME: fname,
      LNAME: lname,
      COMPANY: company,
    }
    event.preventDefault()

    // Mailchimp Groups
    // https://www.gatsbyjs.com/plugins/gatsby-plugin-mailchimp/#mailchimp-groups
    if (aviation.touched) {
      listFields = {
        ...listFields,
        ...{ "group[13510][1]": aviation.value.toString() },
      }
    }

    // Mailchimp Groups
    if (maritime.touched) {
      listFields = {
        ...listFields,
        ...{ "group[13510][2]": maritime.value.toString() },
      }
    }

    // FIXME : These value IDS are INCORRECT and have been GUESSED
    if (energy.touched) {
      listFields = {
        ...listFields,
        ...{ "group[13510][3]": energy.value.toString() },
      }
    }

    if (government.touched) {
      listFields = {
        ...listFields,
        ...{ "group[13510][4]": government.value.toString() },
      }
    }

    if (partner.touched) {
      listFields = {
        ...listFields,
        ...{ "group[13510][5]": partner.value.toString() },
      }
    }



    if (other.touched) {
      listFields = {
        ...listFields,
        ...{ "group[13510][6]": other.value.toString() },
      }
    }

    addToMailchimp(email, listFields)
      .then(data => {
        setMessage(data)
      })
      .catch(error => {
        // FIXME: Show why it failed
        console.log("Mail Error", error)
      })
  }

  let contactForm = formElementsArray.map(formElement => (
    <Input
      key={formElement.id}
      title={
        formElement.config.elementTitle ? formElement.config.elementTitle : null
      }
      elementType={formElement.config.elementType}
      name={formElement.config.name}
      elementConfig={formElement.config.elementConfig}
      value={formElement.config.value}
      invalid={!formElement.config.valid}
      shouldValidate={formElement.config.validation}
      touched={formElement.config.touched}
      changed={event => inputChangedHandler(event, formElement.id)}
    />
  ))

  return (
    <FooterStyles>
      <div id="contact-form" className="form-container">
        <form
          className="footer-form"
          onSubmit={event =>
            handleSubmit(
              event,
              form.email.value,
              form.first_name.value,
              form.last_name.value,
              form.company.value,
              form.industry_aviation,
              form.industry_maritime,

              form.industry_energy,
              form.industry_government,
              form.industry_partner,
              
              form.industry_other
            )
          }
        >
          <h2>Want to hear more?</h2>
          {message.msg ? (
            <p
              className="success-message"
              dangerouslySetInnerHTML={{ __html: message.msg }}
            />
          ) : null}
          {contactForm}
          <Button type="submit" color="neon-navy">
            Subscribe
          </Button>
        </form>
      </div>
      <div className="shadow-hr" />
      <div className="bottom-container">
        <div className="links-col">
          <div className="item">
            <span className="footer-title">Head Office</span>
            <p>1821 E. Dyer Rd.</p>
            <p>Suite 125</p>
            <p>Santa Ana, CA 92705</p>
            <p>USA</p>
            <a href="tel:00310437600" className="footer-link">
              +1 (310) 437-6000
            </a>
          </div>
          <div className="item">
            <span className="footer-title">Privacy</span>
            <OutboundLink
              href="https://www.anuvu.com/sitemap"
              className="footer-link"
              rel="noreferrer"
            >
              Privacy Policy
            </OutboundLink>
            <OutboundLink
              href="https://www.anuvu.com/privacy"
              target="_blank"
              className="footer-link"
              rel="noreferrer"
            >
              Sitemap
            </OutboundLink>
          </div>
          <div className="item socials">
            <span className="social-title">Find us on:</span>
            <div className="social-icons">
              <OutboundLink
                href="https://google.com"
                target="_blank"
                rel="noreferrer"
                className="social-link"
                aria-label="LinkedIn Link"
              >
                <FaLinkedin alt="Link to our LinkedIn" />
              </OutboundLink>
              <OutboundLink
                href="https://google.com"
                target="_blank"
                rel="noreferrer"
                className="social-link"
                aria-label="Twitter Link"
              >
                <FaTwitter alt="Link to our Twitter" />
              </OutboundLink>
              <OutboundLink
                href="https://google.com"
                target="_blank"
                rel="noreferrer"
                className="social-link"
                aria-label="YouTube Link"
              >
                <FaYoutube alt="Link to our Youtube" />
              </OutboundLink>
            </div>
          </div>
        </div>
        <div className="item visit-anuvu">
          <OutboundLink
            href="https://www.anuvu.com/"
            className="footer-link"
            rel="noreferrer"
            aria-label="Click here to visit the Anuvu Website"
          >
            Visit anuvu.com
            <span className="arrow">
              <FaChevronRight />
            </span>
          </OutboundLink>
          <span className="legal">All Rights Reserved &copy; Anuvu.</span>
        </div>
      </div>
    </FooterStyles>
  )
}

export default Footer
