import { createGlobalStyle } from "styled-components"

const GlobalStyles = createGlobalStyle`
    :root {
        --white: #fff;
        --navy: rgb(35, 49, 60);
        --dark-navy: #1d2224;
        --navy: #002b44;
        --light-blue: #587a94;
        --blue: #244053;
        --neon: #60f0eb;
        --pale-pink: #e5c2b5;

       
        // Spacing
        --spacing: 16px;

        // Width
        --content-width: 1100px;
        --text-width: 750px;
        // Line-height
        --line-height: 1.6;

        // Fonts
        --font-titles: 'Core Sans M', sans-serif;
        --font-bold-titles: 'Core Sans EB', sans-serif;
        --font-main: 'Core Sans R', sans-serif;
        --font-light: 'Core Sans L', sans-serif;
        --font-extra-light: 'Core Sans E L', sans-serif;
        --font-thin: 'Core Sans T', sans-serif;
    }

    html {
        font-size: 16px;
        font-family: var(--font-main);
        line-height: var(--line-height);
        text-rendering: optimizelegibility;
        -webkit-font-smoothing: antialiased;
        letter-spacing: 1px;
        scroll-behavior: smooth;
        background-color:#010204;//var(--navy);
    }

    html, body {
      overflow-x: hidden;
        margin: 0;
        padding: 0;
        * {
            box-sizing: border-box;
        }
    }

    img {
        max-width: 100%;
    }

    ul {
        margin: 0;
        padding: 0;
    }

    main > section{
      pointer-events: none;
    }

    main > section > *{
      pointer-events: auto;
    }

    // Underlines
    .has-underline {
        position: relative;
        padding-bottom: calc(var(--spacing) * 1.5);

        &:before,
        &:after {
          content: "";
          position: absolute;
          bottom: 0;
          width: 0%;
          height: 1px;
          line-height: 1;
        }
      }
    
      .left .has-underline {
        &:after {
          left: 0;
          background: rgba(255, 255, 255, 1);
        }
        &:before {
          right: 0;
          background: rgba(255, 255, 255, 0.3);
        }
      }
      .right .has-underline {
        &:after {
          right: 0;
          background: rgba(255, 255, 255, 1);
        }
        &:before {
          left: 0;
          background: rgba(255, 255, 255, 0.3);
        }
      }

      // Overline
    .has-overline {
      position: relative;
      padding-top: var(--spacing);
      &:before,
      &:after {
        content: '';
        position: absolute;
        top: 0;
        width: 50%;
        height: 1px;
        line-height: 1;
      }
    }

    .left .has-overline {
      &:after {
        left: 0;
        background: rgba(255, 255, 255, 1);
      }
      &:before {
        right: 0;
        background: rgba(255, 255, 255, 0.3);
      }
    }

    .right .has-overline {
      &:after {
        right: 0;
        background: rgba(255, 255, 255, 1);
      }
      &:before {
        left: 0;
        background: rgba(255, 255, 255, 0.3);
      }
    }
    
@keyframes RevealActiveTimeLineItem{
  from{
    transform:scale(0.1 );
  }
}
`

export default GlobalStyles
