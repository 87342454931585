import React, { useEffect } from "react"
import { TimelineItemStyles } from "./TimelineItemStyles.styles"
import GEO from "../../svgs/large-geo.svg"
import DashedLine from "../UI/DashedLine"

import CountUp from "react-countup"
import { useInView } from "react-intersection-observer"

import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

import UnderlineLeft from "../../svgs/underline-left.svg"
import UnderlineRight from "../../svgs/underline-right.svg"

gsap.registerPlugin(ScrollTrigger)

const TimelineItem = () => {
  useEffect(() => {
    ScrollTrigger.batch(".upper-text", {
      start: "100px bottom",
      onEnter: (elements, element, triggers) => {
        const tl = gsap.timeline()
        tl.to(elements, {
          opacity: 1,
          y: 0,
          duration: 1,
          stagger: {
            each: .5,
            onComplete() {
              gsap.fromTo(
                this._targets[0].parentNode.lastChild,
                {
                  opacity: 0,
                },
                { opacity: 1, duration: 2 }
              )
            },
          },
        })
      },
    })
  }, [])

  const COUNT_UP_START = 0

  const { ref, inView, entry } = useInView({
    threshold: 0,
    triggerOnce: false,
  })

  return (
    <TimelineItemStyles>
      <DashedLine height="400" length="170" v="450" m="150" />
      <div className="grid">
        <div className="left">
          <div className="item">
            <h2 className="upper-text" ref={ref}>
              <CountUp
                start={inView ? COUNT_UP_START : 40}
                end={40}
                delay={0}
                duration={5}
                suffix="+"
              >
                {({ countUpRef }) => (
                  <span className="number" ref={countUpRef} />
                )}
              </CountUp>
              Years
            </h2>
            <UnderlineLeft />
            <p className="lower-text">
              Heavy GEO was the only satellite option, orbiting the earth for
              some 40 years.
            </p>
          </div>
          <div className="item">
            <h2 className="upper-text">5+ years to build</h2>
            <UnderlineLeft />
            <p className="lower-text">
              Can take over five years to design and build.
            </p>
          </div>
          <div className="item">
            <h2 className="upper-text">Once launched, they're locked</h2>
            <UnderlineLeft />
            <p className="lower-text">
              Analog components limit operational flexibility to changing ground
              conditions.
            </p>
          </div>
        </div>
        <GEO />
        <div className="right">
          <div className="item">
            <h2 className="upper-text">
              More than <span className="miles">22,000 miles</span> from earth
            </h2>
            <UnderlineRight />
            <div className="lower-text">
              <p>
                Historically bulky, static, and with designs based on legacy
                ways of thinking.
              </p>
              <span className="metric">more than 6 tons</span>
            </div>
          </div>
          <div className="item">
            <h2 className="upper-text">15+ years</h2>
            <UnderlineRight />
            <p className="lower-text">
            With long periods of using the same technology and broad, continent-sized coverage areas, there is no room to maneuver or advance as technology evolves.
            </p>
          </div>
        </div>
      </div>
      <DashedLine height="240" length="240" v="1000" m="150" />
    </TimelineItemStyles>
  )
}

export default TimelineItem
