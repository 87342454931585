import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import { convertToBgImage } from "gbimage-bridge";
import BackgroundImage from "gatsby-background-image";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import styled from "styled-components";
import Rems from "../../styles/mixins/Rems";
import Logo from "../../svgs/anuvu-logo.svg";

import { OutboundLink } from "gatsby-plugin-google-gtag";

const PostsStyles = styled(BackgroundImage)`
  background-color: var(--dark-navy);
  position: relative;
  padding: calc(var(--spacing) * 28) 0 calc(var(--spacing) * 6);
  margin-top: -400px;
  h2 {
    margin-top: 0;
    color: var(--navy);
    max-width: var(--content-width);
    margin-left: auto;
    margin-right: auto;
  }
  h3 {
    color: var(--navy);
    margin-bottom: 0;
  }
  .gatsby-image-wrapper {
    height: 100%;
    z-index: -1;
  }
  .overlay-container {
    display: flex;
    align-items: center;
    flex-flow: column nowrap;
    svg {
      max-width: 400px;
    }
  }

  .posts-container {
    max-width: 1200px;
    background: rgba(255, 255, 255, 0.75);
    padding: calc(var(--spacing) * 5) 0 calc(var(--spacing) * 6);
    width: 90%;
    z-index: 10;
    margin: calc(var(--spacing) * 6) auto;
    h2 {
      width: 90%;
      letter-spacing: 3px;
    }
    .posts-grid {
      width: 90%;
      max-width: var(--content-width);
      margin: auto;
      gap: calc(var(--spacing) / 2) calc(var(--spacing) * 3);
      .post {
        border-bottom: 1px solid #4c6778;
        padding-bottom: calc(var(--spacing) / 1);
        h3 {
          ${Rems({ type: "font-size", size: 26 })}
          letter-spacing: 2px;
        }

        p {
          line-height: 1.4;
        }

        .link {
          font-family: var(--font-main);
          font-weight: 600;
          letter-spacing: 2px;
          line-height: 1.3;
        }
      }
      .post:first-of-type {
        grid-area: one;
      }
      .post:nth-of-type(2) {
        grid-area: two;
      }
      .post:nth-of-type(3) {
        grid-area: three;
      }
      .visit-container {
        grid-area: four;
      }
    }

    .visit-container {
      padding-top: calc(var(--spacing) * 2);
      .link {
        ${Rems({ type: "font-size", size: 26 })};
        font-family: var(--font-light);
      }
    }
  }
  @media screen and (min-width: 768px) {
    .posts-container .posts-grid {
      display: grid;
      grid-template-areas: "one two" "one three" "one four";
    }

    .posts-container {
      margin: calc(var(--spacing) * 9) auto calc(var(--spacing) * 6);
      padding-left: calc(var(--spacing) * 2);
      padding-right: calc(var(--spacing) * 2);
    }
  }
`;

gsap.registerPlugin(ScrollTrigger);
const PostsSection = () => {
  useEffect(() => {
    gsap.fromTo(
      ".posts-container",
      {
        opacity: 0,
        y: 100,
      },
      {
        duration: 3,
        opacity: 1,
        y: 0,
        scrollTrigger: {
          start: "0px bottom",
          trigger: ".posts-container",
        },
      }
    );
  }, []);

  const { background } = useStaticQuery(
    graphql`
      query {
        background: file(relativePath: { eq: "stars-post-background.png" }) {
          childImageSharp {
            gatsbyImageData(quality: 100, formats: [AUTO, WEBP])
          }
        }
      }
    `
  );
  const image = getImage(background);
  const bgImage = convertToBgImage(image);
  const [posts, setPosts] = useState([
    {
      id: 1,
      title: "About Anuvu",
      text: [
        "For nearly two decades Anuvu’s team of global experts have made us the leading provider of high-speed connectivity and entertainment solutions for demanding worldwide mobility markets, focused on next-generation passenger and guest-connected experiences in the air and at sea.",
        "Through long-standing customer relationships we have a proven track record of meeting our customers’ needs, even as the world changes. Anuvu’s flexible and agile approach enables us to adopt today’s newest technologies, while optimizing for tomorrow, so that we may tailor solutions to our customers’ business and brand.",
      ],
      link: "https://anuvu.com/",
      is_internal: false,
      link_text: "Learn more",
    },
    {
      id: 2,
      title: "Video: The Anuvu Constellation ",
      text: [
        "Learn more about the Anuvu Constellation and our partnership with Astranis.",
      ],
      link: "https://vimeo.com/788815089/1a694223d3",
      is_internal: false,
      link_text: "Watch video",
    },
    {
      id: 3,
      title: "Press release: Anuvu Secures Major Capacity Deal with Teleset",
      text: [
        "Los Angeles, CA and Ottawa, Canada – February 14, 2022 – Anuvu and Telesat (NASDAQ and TSX: TSAT) have announced today the largest yet in a series of satellite capacity deals, providing Anuvu and its customers with new Ka-band connectivity over the Southeastern US, the Caribbean, the Gulf of Mexico and Central America.",
      ],
      link: "https://www.anuvu.com/our-company/press-releases/detail/250/anuvu-announces-high-performance-microgeo-satellite-constellation",
      is_internal: false,
      link_text: "Read full release",
    },
  ]);

  return (
    <PostsStyles Tag="section" {...bgImage}>
      <div>
        <GatsbyImage
          image={image}
          alt={"test-image"}
          style={{ position: "absolute", bottom: 0 }}
        />
      </div>
      <div className="overlay-container">
        <Logo />
        <div className="posts-container">
          <h2>Latest news from our world</h2>
          <div className="posts-grid">
            {posts.map((post) => (
              <div className="post" key={post.id}>
                <h3>{post.title}</h3>
                {post.text.map((text, index) => (
                  <p key={index}>{text}</p>
                ))}
                {!post.is_internal ? (
                  <OutboundLink
                    href={post.link}
                    target="_blank"
                    className="link navy-neon"
                  >
                    {post.link_text}
                  </OutboundLink>
                ) : (
                  <a href={post.link} className="link navy-neon">
                    Read more
                  </a>
                )}
              </div>
            ))}
            <div className="visit-container">
              <OutboundLink
                href="https://www.anuvu.com/our-company/newsroom/press-releases"
                className="link navy-neon"
              >
                Visit the full newsroom and blog for more insights.
              </OutboundLink>
            </div>
          </div>
        </div>
      </div>
    </PostsStyles>
  );
};

export default PostsSection;
