import React from "react"
import AstranisItem from "../TimelineItem/AstranisItem/AstranisItem"
import TelesatItem from "../TimelineItem/TelesatItem/TelesatItem"
import TimelineItem from "../TimelineItem/TimelineItem"
import DashedLine from "../UI/DashedLine"
import { TimelineStyles } from "./TimelineStyles.styles"
import { FaChevronDown } from "react-icons/fa"
import { StaticImage } from "gatsby-plugin-image"
import { Parallax, useController } from "react-scroll-parallax"

const TimelineSection = () => {

  const controller = useController();

  // Patches to allow build
  const parallaxController = controller ?  
    controller.parallaxController : {
      update:()=>{
        console.log("Parallax Controller needs window", window)
      }
    };

  // when an image loads refresh the parallax calculations
  const handleLoad = () => {
    parallaxController.update();
  }
  
  return (
    <TimelineStyles>
      <div className="container">
        <Parallax y={[30, -18]}>
          <div>
            <h3 className="white-title entry">Where we've been:</h3>
            <div className="start-timeline">
              <p className="cross">&#10005;</p>
              <DashedLine height="85" length="85" v="150" m="150" />
            </div>
            <h3 className="neon-title">Heavy geostationary satellites (GEO)</h3>
            <TimelineItem />
          </div>
        </Parallax>
        <div className="star-clouds">
          <StaticImage
            src="../../images/starry-cloud-2.png"
            alt="Starry Cloud"
            className="left-cloud"
            objectFit="contain"
            objectPosition="left"
            onLoad={handleLoad}
          />
          <StaticImage
            src="../../images/starry-cloud.png"
            alt="Starry Cloud"
            className="right-cloud"
            objectFit="contain"
            objectPosition="right"
            onLoad={handleLoad}
          />
        </div>

        <Parallax y={[ 2, -20 ]}>
          <div>
            <div className="timeline-spacer">
              <h3 className="white-title">Where we're headed</h3>
              <FaChevronDown className="chevron" />
              <h3 className="white-glow">Anuvu Constellation</h3>
              <DashedLine height="80" length="100" v="150" m="150" />
              <h3 className="neon-title">Astranis Microgeo</h3>
            </div>
            <AstranisItem />
            <h3 className="neon-title">LEO Constellations</h3>
            <TelesatItem />
          </div>
        </Parallax>

        <div className="milky-way">
          <StaticImage
            src="../../images/milky-way.png"
            alt="milky way"
            className="milky-way"
            objectFit="contain"
            objectPosition="right"
            onLoad={handleLoad}
          />
        </div>
        <div className="timeline-end">
          <div className="something">
            <h3 className="white-title">The future</h3>
            <FaChevronDown className="chevron" />
            <h3 className="neon-title">LEO AND MICROGEO, WORKING TOGETHER</h3>
            <p>
              Heavy GEOs will take a backseat while LEO becomes primary provider
              of connectivity. MicoGEOs provide support through
              targeted beams and dedicated network capacity, providing the
              ultimate flexibility, speed, and efficiency.
            </p>
          </div>
        </div>
      </div>
    </TimelineStyles>
  )
}

export default TimelineSection
