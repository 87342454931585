import { createGlobalStyle } from "styled-components"
import Rems from "./mixins/Rems"

import medium from "../fonts/coresans-medium.ttf"
import font from "../fonts/coresans-regular.ttf"
import light from "../fonts/coresans-light.ttf"
import eb from "../fonts/coresans-ExtraBold.ttf"
import el from "../fonts/coresans-extralight.otf"
import thin from "../fonts/coresans-thin.otf"

const Typography = createGlobalStyle`
    @font-face {
        font-family: 'Core Sans EB';
        src: url(${eb});
        font-weight: bold;
        font-style: normal;
        font-display: swap;
    }


    @font-face {
        font-family: 'Core Sans R';
        src: url(${font});
        font-weight: normal;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Core Sans M';
        src: url(${medium});
        font-weight: normal;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Core Sans B';
        src: url('../fonts/coresans-bold.woff');
        font-weight: bold;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Core Sans L';
        src: url(${light});
        font-weight: 300;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Core Sans E L';
        src: url(${el});
        font-weight: 100;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: 'Core Sans T';
        src: url(${thin});
        font-weight: 100;
        font-style: normal;
        font-display: swap;
    }

    h1 {
        ${Rems({ type: "font-size", size: 36 })};

        @media screen and (min-width: 768px) {
            ${Rems({ type: "font-size", size: 40 })};
        }

        @media screen and (min-width: 1024px) {
            ${Rems({ type: "font-size", size: 42 })};
        }
    }

    h2 {
        ${Rems({ type: "font-size", size: 30 })};
        @media screen and (min-width: 768px) {
            ${Rems({ type: "font-size", size: 34 })};
        }

        @media screen and (min-width: 1024px) {
            ${Rems({ type: "font-size", size: 38 })};
        }
    }

    p {
        ${Rems({ type: "font-size", size: 16 })};

        @media screen and (min-width: 1024px) {
            ${Rems({ type: "font-size", size: 18 })};
        }
    }

    button.cta.btn{
        min-width: 175px;
    }
    a.cta.btn,
    button.cta.btn {
        background: transparent;
        padding: 8px 30px;
        text-transform: uppercase;
        text-decoration: none;
        box-shadow: unset;
        text-shadow: unset;
        letter-spacing: 4px;
        border-radius: 30px;
        ${Rems({ type: "font-size", size: 16 })};
        display: inline-block;
        
        text-align: center;
        line-height: 1;
        font-family: var(--font-bold-titles);
        margin: var(--spacing) 0;
        transition: all .3s ease-in-out;
        cursor: pointer;
        &.neon {
            color: var(--neon);
            border: 2px solid var(--neon);
            box-shadow: 0 0px 6px .5px var(--neon);
         
            &:hover,
            &:active,
            &:focus {
              color: var(--navy);
              background: var(--neon);
              transform: scale(1.03);
            }
        }
    }



    a.link {
        text-decoration: none;
        transition: all .3s ease-in-out;
        &.navy-neon {
            color: var(--navy);
            &:hover {
                color: var(--neon);
            }
        };
    }
`

export default Typography
