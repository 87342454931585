import styled from "styled-components"
import Rems from "../../styles/mixins/Rems"

export const HeaderStyles = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column nowrap;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.8) 3%,
    rgba(0, 0, 0, 0) 8%,
    rgba(0, 0, 0, 0) 100%
  );
  
  .text-container {
    position: absolute;
    max-width: var(--text-width);
    margin-top:-50px;
    text-align: center;
    color: var(--neon);
    width: 90%;
    z-index:15;
  }

  h1 {
    ${Rems({ type: "font-size", size: 20 })};
    letter-spacing: 2px;
    margin-top: calc(var(--spacing) * 2);
  }

  h2{
    font-size:120%;
    line-height:1;
    margin-top: calc(var(--spacing) * 3);
    margin-bottom: calc(var(--spacing) * 1.5);
  }

  a.cta.btn{

    --space:4;
    order:-1;
    line-height:1.3;
    z-index:909;
    position:relative;
    align-self:center;
    text-align:center;
    margin-top:calc( var(--spacing)  );
   
    max-width: min( calc( 100vw - var(--spacing) * 2 ), var(--text-width) );
  }


  .logo {
    max-width: 450px;
  }

  .gatsby-image-wrapper.background {
    z-index: -1;
    min-height: 650px;
    max-height: 850px;
    padding-bottom: 200px;
  }



  @media screen and (min-width: 242px) {
    a.cta.btn{
      margin-top:calc( var(--spacing) * 2 );
    }
  }
  @media screen and (min-width: 640px) {
    a.cta.btn{
      margin-top:calc( var(--spacing) * 3 );
    }
  }
  @media screen and (min-width: 768px) {

    a.cta.btn{
      position:absolute;
      margin-top: 0;
      top:calc(var(--spacing) * var(--space));
      right:calc(var(--spacing) * var(--space));
    }
    .text-container {

      margin-top:-50px;
      padding: calc(var(--spacing) * 10) 0 calc(var(--spacing) * 3);
    }
    .gatsby-image-wrapper.background {
      max-height: 900px;
    }
    .logo {
      max-width: 525px;
    }
    h1 {
      ${Rems({ type: "font-size", size: 32 })};
      margin-top: calc(var(--spacing) * 3);
    }
    h2{
      letter-spacing: 0.25rem;
    }
  }

  
  @media screen and (min-width: 909px) {
    .text-container {
      margin-top:0;
      padding-top:0;
    }
  }
  
  @media screen and (min-width: 1111px) {
    .text-container {
      margin-top:-50px;
    }
  }

  NB. we want the cta always above the logo
  @media screen and (min-width:1111px) and (max-height:606px)
  {
    // .text-container {
    //   margin-top:-200px;
    // }
  }
`
