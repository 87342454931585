import styled from "styled-components"
import Rems from "../../styles/mixins/Rems"

export const ConnectivityStyles = styled.section`
  background: rgb(229, 194, 181);
  background: linear-gradient(
      180deg,
      rgba(103, 143, 182, 1) 0%,
      rgba(88, 122, 148, 0) 35%,
      rgba(88, 122, 148, 0) 83%,
      rgba(221, 195, 181, 0.7) 91%,
      rgba(238, 195, 179, 0.5) 95%,
      rgba(204, 179, 174, 1) 100%
    ),
    linear-gradient(
      40deg,
      rgba(238, 195, 179, 1) 0%,
      rgba(116, 136, 155, 1) 70%,
      rgba(88, 122, 148, 1) 100%
    );
  position: relative;
  z-index: 1;
  padding: 50px 0 200px;
  color: white;

  div.container {
    width: 90%;
    max-width: calc(var(--content-width) + 150px);
    margin-left: auto;
    margin-right: auto;
    margin-top: -150px;
  }

  .text-container,
  .title-container {
    max-width: var(--content-width);
    margin-left: auto;
    margin-right: auto;
  }
  div.title-container {
    text-align: center;
    h2 {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-flow: column nowrap;
    }
  }

  .text-container {
    display: grid;
    align-items: center;
    margin: calc(var(--spacing) * 6) auto calc(var(--spacing) * 6);
    .left {
      text-align: left;
      max-width: 600px;
    }
    div.right {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      position: relative;
      padding-bottom: calc(var(--spacing) * 2);
      opacity: 0.5;
      &:before,
      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        height: 1px;
        line-height: 1;
      }
      &:before {
        width: 100%;
        left: 0;
        background: rgba(255, 255, 255, 0.3);
      }
      &:after {
        width: 50%;
        min-width: 75px;
        max-width: 150px;
        right: 0;
        background: rgba(255, 255, 255, 1);
      }

      h3 {
        display: flex;
        text-align: center;
        flex-flow: column nowrap;
        font-family: var(--font-extra-light);
        font-weight: 400;
        margin: 0;
        line-height: 1;
        letter-spacing: 6px;
        text-transform: uppercase;
        ${Rems({ type: "font-size", size: 36 })};
        span {
          ${Rems({ type: "font-size", size: 116 })};
          letter-spacing: 12px;
          font-family: var(--font-thin);
        }
      }
    }
  }

  .header {
    overflow: hidden;
    .connectivity-underline {
      width: 100%;
    }
    h2 {
      position: relative;
      padding-bottom: calc(var(--spacing) * 2);
      margin-bottom: 0;
      margin-right: auto;
      margin-left: auto;
      letter-spacing: 2px;
      max-width: 900px;
      line-height: 1.3;
    }
  }

  .welcome {
    overflow: hidden;
    .anuvu-underline {
      width: 100%;
    }
    h2 {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-flow: column nowrap;
      letter-spacing: 2px;
      ${Rems({ type: "font-size", size: 40 })};
      margin-bottom: calc(var(--spacing) * 2);
      position: relative;
      line-height: 1;
      span {
        font-family: var(--font-extra-light);
        ${Rems({ type: "font-size", size: 22 })};
        text-transform: uppercase;
        line-height: 1.5;
        margin-bottom: var(--spacing);
      }
    }
  }

  p.center {
    max-width: var(--content-width);
    margin: calc(var(--spacing) * 4) auto 0;
    text-align: center;
  }

  @media screen and (min-width: 500px) {
    .text-container {
      grid-template-columns: 60% 40%;
      div.right:before {
        width: 130%;
        left: -30%;
      }
    }
  }

  @media screen and (min-width: 768px) {
    margin-top: -50px;
    .header {
      h2 {
        ${Rems({ type: "font-size", size: 42 })};
      }
    }
    .welcome h2 {
      ${Rems({ type: "font-size", size: 58 })};

      span {
        ${Rems({ type: "font-size", size: 30 })};
        letter-spacing: 4px;
      }
    }
  }

  @media screen and (min-width: 1024px) {
    margin-top: -200px;

    .welcome h2 {
      ${Rems({ type: "font-size", size: 74 })};

      span {
        ${Rems({ type: "font-size", size: 32 })};
      }
    }
    .welcome p, .center {
        ${Rems({ type: "font-size", size: 22 })};
        padding: 0 calc(var(--spacing) * 2);
    }
    .text-container div.right h3 {
      ${Rems({ type: "font-size", size: 38 })};
      margin-right: -20px;  
      span.number {
        ${Rems({ type: "font-size", size: 170 })};
      }
    }
  }

  @media screen and (min-width: 1250px) {
    margin-top: -250px;

    .welcome h2 {
      ${Rems({ type: "font-size", size: 82 })};

      span {
        ${Rems({ type: "font-size", size: 36 })};
      }
    }
  }
`
