import styled from "styled-components"
import Rems from "../../styles/mixins/Rems"

export const TimelineItemStyles = styled.div`
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 175px 0px;
  position: relative;
  .grid {
    display: grid;
    width: 90%;
    position: relative;
    align-items: center;
    max-width: var(--content-width);
    margin-left: auto;
    margin-right: auto;
    gap: calc(var(--spacing) * 3) 0;

    .left {
    }

    svg#GeoSVG {
      z-index: 10;
      display: block;
      margin-right: auto;
      margin-left: auto;
    }
  }
  .lineLeft {
    position: absolute;
    right: 0;
  }
  .lineRight {
    position: absolute;
    left: 0;
  }
  .item .upper-text {
    transform: translateY(60px);
    opacity: 0;
    line-height: 1;
    margin-bottom: var(--spacing);
  }
  .item .lower-text {
    opacity: 0;
  }

  /* Custom Items */
  .left {
    .item {
      position: relative;
      h2 {
        margin-bottom: calc(var(--spacing) * 1.5);
      }
      p {
        margin-top: calc(var(--spacing) * 3);
      }
      &:first-of-type {
        h2 {
          font-family: var(--font-thin);
          display: flex;
          align-items: flex-start;
          flex-flow: column nowrap;
          text-transform: uppercase;
          font-weight: unset;
          letter-spacing: 10px;
          line-height: 1.1;
          span.number {
            ${Rems({ type: "font-size", size: 80 })};
            font-family: var(--font-extra-light);
          }
        }
      }

      &:nth-of-type(2) {
        margin: calc(var(--spacing) * 3) 0;
        h2 {
          ${Rems({ type: "font-size", size: 34 })};
          font-weight: 100;
          font-family: var(--font-extra-light);
        }
      }

      &:nth-of-type(3) {
        h2 {
          ${Rems({ type: "font-size", size: 28 })};
          font-family: var(--font-extra-light);
          font-weight: unset;
          line-height: 1.3;
        }
      }
    }
  }

  .right {
    .item {
      position: relative;
      p {
        margin-top: calc(var(--spacing) * 3);
      }
      &:nth-of-type(1) {
        margin-bottom: calc(var(--spacing) * 3);
        span.metric {
          font-family: var(--font-extra-light);
          ${Rems({ type: "font-size", size: 30 })};
        }
        p {
          margin-bottom: 0;
        }
        h2 {
          display: flex;
          margin-bottom: calc(var(--spacing) * 1.5);
          flex-flow: column nowrap;
          font-family: var(--font-light);
          line-height: 1.3;
          ${Rems({ type: "font-size", size: 18 })};
          span.miles {
            ${Rems({ type: "font-size", size: 36 })};
            font-weight: 100;
            font-family: var(--font-extra-light);
          }
        }
      }
      &:nth-of-type(2) h2 {
        text-transform: uppercase;
        font-family: var(--font-thin);
        letter-spacing: 10px;
        margin-bottom: calc(var(--spacing) * 1.5);

        font-weight: unset;
      }
    }
  }

  .lineLeft,
  .lineRight {
    width: 100%;
  }

  svg.dash {
    position: absolute;
    &:first-of-type {
      top: 0;
    }
    &:last-of-type {
      bottom: -100px;
    }
  }

  @media screen and (min-width: 768px) {
    padding: calc(var(--spacing) * 3) 0;
    .grid {
      grid-template-columns: 30% 40% 30%;

      svg#GeoSVG {
        width: 115%;
        margin-left: -13%;
      }
    }

    .left .item:nth-of-type(2) {
      margin: calc(var(--spacing) * 5) 0;
    }
    .right {
      display: flex;
      flex-flow: column nowrap;
      justify-content: space-between;
      .item:first-of-type {
        margin: 0px auto calc(var(--spacing) * 4);
      }
      .item:nth-of-type(2) {
      }
    }
  }

  @media screen and (min-width: 768px) {
    svg.dash:nth-of-type(2) {
      bottom: 0;
    }
  }

  @media screen and (min-width: 1024px) {
    padding-bottom: calc(var(--spacing) * 8);
    .left {
      .item:first-of-type {
        padding-right: calc(var(--spacing) * 2);
        svg {
          width: 120%;
          margin-right: -20%;
        }
      }
      .item:nth-of-type(2) {
        margin: calc(var(--spacing) * 6) 0;
        h2 {
        }
        p {
          padding-right: calc(var(--spacing) * 2);
        }
        svg {
          width: 120%;
          margin-right: -20%;
        }
      }
      .item:nth-of-type(3) {
        h2 {
          ${Rems({ type: "font-size", size: 34 })};
        }
        svg {
          width: 135%;
          margin-right: -35%;
        }
      }
    }
    .right {
      .item:first-of-type {
        h2,
        p,
        span.metric {
          padding-left: calc(var(--spacing) * 3);
        }
        svg {
          width: 110%;
          margin-left: -10%;
        }
      }
      .item:nth-of-type(2) {
        margin-bottom: -225px;
        margin-top: calc(var(--spacing) * 9);
        h2,
        p {
          padding-left: calc(var(--spacing) / 2);
        }
        svg {
          width: 135%;
          margin-left: -35%;
        }
      }
    }
  }
`
