import styled from "styled-components"
import Rems from "../../../styles/mixins/Rems"

export const TelesatItemStyles = styled.div`
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 150px 0 250px;
  svg.dash {
    position: absolute;
    max-width: 450px;
    &:first-of-type {
      top: 0;
    }
    &:last-of-type {
      bottom: -200px;
    }
  }

  .grid {
    display: grid;
    position: relative;
    align-items: center;
    width: 90%;
    max-width: var(--content-width);
    margin-left: auto;
    margin-right: auto;
    gap: calc(var(--spacing) * 3) 0;
    svg#leoNetwork {
      z-index: 10;
      margin: 130px auto auto;
      max-width: 250px;
    }
  }

  .item h2 {
    padding-bottom: var(--spacing);
    line-height: 1;
    margin-bottom: var(--spacing);
  }
  .item .upper-text {
    opacity: 0;
    transform: translateY(60px);
  }
  .item .lower-text {
    opacity: 0;
  }

  .left {
    .item {
      position: relative;
      &:first-of-type {
        margin-bottom: calc(var(--spacing) * 3);
        h2 {
          display: flex;
          flex-flow: column nowrap;
          font-size: 16px;
          line-height: 1.3;
          font-weight: 100;
          span {
            ${Rems({ type: "font-size", size: 86 })};
            font-family: var(--font-thin);
          }
        }
      }
      &:nth-of-type(2) {
        margin: calc(var(--spacing) * 5) 0;
        p {
          line-height: 1.3;
          display: flex;
          flex-flow: column nowrap;
          span {
            ${Rems({ type: "font-size", size: 34 })};
            font-family: var(--font-extra-light);
          }
        }
      }
      &:nth-of-type(3) {
        h2 {
          ${Rems({ type: "font-size", size: 38 })};
          font-family: var(--font-extra-light);
          font-weight: 100;
          margin-bottom: 0;
        }
      }
    }
  }

  .right .item {
    position: relative;

    &:first-of-type {
      margin-bottom: calc(var(--spacing) * 8);
      p {
        font-family: var(--font-light);
        span {
          ${Rems({ type: "font-size", size: 30 })};
          line-height: 1.3;
        }
      }
      h2 {
          ${Rems({ type: "font-size", size: 44 })};
          font-family: var(--font-extra-light);
          font-weight: 100;
          margin-bottom: 0;
        }
    }
    &:nth-of-type(2) {
      h2 {
        ${Rems({ type: "font-size", size: 30 })};
        text-transform: uppercase;
        line-height: 1.5;
        font-family: var(--font-thin);
        letter-spacing: 10px;
        font-weight: 100;
        margin-bottom: 0;
      }
    }
  }

  .lineLeft,
  .lineRight {
    width: 100%;
    position: absolute;
  }
  .lineLeft {
    right: 0;
  }
  .lineRight {
    left: 0;
  }

  .parallax-outer {
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: -1;
    .parallax-inner {
      position: relative;
      height: 100%;
    }
  }

  .parallax {
    position: absolute;
    font-family: var(--font-thin);
    opacity: 0.2;
    letter-spacing: 4px;
    font-weight: unset;

    &.beyond {
      bottom: 0px;
      right: 20%;
      ${Rems({ type: "font-size", size: 50 })};
    }
  }

  @media screen and (min-width: 768px) {
    padding: calc(var(--spacing) * 3) 0 calc(var(--spacing) * 8);
    .grid {
      grid-template-columns: repeat(3, 1fr);
    }
    .left .item:first-of-type {
      overflow: visible;
    }

    .left .item:first-of-type,
    .left .item:last-of-type {
      svg {
        width: 115%;
        margin-right: -15%;
      }
    }
    .left .item:nth-of-type(2) {
      margin: calc(var(--spacing) * 7) 0;
      svg {
        width: 120%;
        margin-right: -20%;
      }
    }
    .right .item {
      &:first-of-type {
        p {
          line-height: 1.3;
          padding-left: calc(var(--spacing) * 2);
        }
        h2 {
          padding-left: calc(var(--spacing) * 2);
          ${Rems({ type: "font-size", size: 32 })};
        }
      }
      &:nth-of-type(2) {
        h2 {
          ${Rems({ type: "font-size", size: 26 })};
        letter-spacing: 4px;

        }
        h2,
        p {
          padding-left: calc(var(--spacing) * 2);
        }
      }
    }
    svg.dash:last-of-type {
      bottom: 0;
    }
    .parallax.beyond {
      ${Rems({ type: "font-size", size: 46 })};
      right: 10%;
    }
  }

  @media screen and (min-width: 1024px) {
    .parallax.beyond {
      ${Rems({ type: "font-size", size: 56 })};
      right: 18%;
    }
    .right .item:first-of-type {
      svg {
          width: 110%;
          margin-left: -10%;
        }
     h2 {
      ${Rems({ type: "font-size", size: 44 })};

    }
  }
    .right .item:nth-of-type(2) {
      svg {
          width: 110%;
          margin-left: -10%;
        }
      h2 {
        ${Rems({ type: "font-size", size: 28 })};
        letter-spacing: 10px;

      }
    }
  }
  @media screen and (min-width: 1200px) {
    .right .item:nth-of-type(2) h2 {
      ${Rems({ type: "font-size", size: 38 })};
    }
  }
`
