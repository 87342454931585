import React, { useEffect } from "react"

import { StatusTimelineSectionStyles } from "./StatusTimelineSection.styles"

const StatusTimelineSection = ({progress=1}) => {
    
  const tasks = [
    "Development",
    "Production",
    "Delivery",
    "Launch",
    "Entry into Service"
  ]

  useEffect(() => {
    
  }, [])

  return (
    <StatusTimelineSectionStyles>
      <h2>Countdown to the constellation</h2>
      
      <ul role="list" style={{"--active":progress}}>
        {
          tasks.map( (task,i)=>(
            <li key={`company-progress-${i}`} className={i===progress ? 'active' : ''} >{task}</li>
          ) )
        }
      </ul>

      <meter
        aria-label="Countdown to the constellation"
        value={progress} 
        min="0" 
        max="5">
        {progress+1} steps completed out of 5 steps total
      </meter>

    </StatusTimelineSectionStyles>
  )
}

export default StatusTimelineSection
  