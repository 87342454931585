import styled from "styled-components"
import Rems from "../../../styles/mixins/Rems"

export const AstranisStyles = styled.div`
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 175px 0px;

  svg.dash {
    position: absolute;
    &:first-of-type {
      top: 0;
    }
    &:last-of-type {
      bottom: 0;
    }
  }
  .grid {
    display: grid;
    position: relative;
    align-items: center;
    width: 90%;
    max-width: var(--content-width);
    margin-left: auto;
    margin-right: auto;
    gap: calc(var(--spacing) * 3) 0;

    svg#microGeo {
      max-width: 150px;
      z-index: 10;
      margin: auto;
    }
  }

  .dash:nth-of-type(2) {
    height: 150px;
    overflow: hidden;
  }

  .item h2 {
    padding-bottom: var(--spacing);
    line-height: 1;
    margin-bottom: var(--spacing);
  }

  .left {
    .item {
      position: relative;

      &:first-of-type {
        h2 {
          display: flex;
          flex-flow: column nowrap;
          text-transform: uppercase;
          ${Rems({ type: "font-size", size: 46 })}
          font-family: var(--font-thin);
          font-weight: 100;
          margin: 0;
          letter-spacing: 8px;
          span.number {
            ${Rems({ type: "font-size", size: 86 })};
            font-family: var(--font-extra-light);
          }
        }
      }
      &:nth-of-type(2) {
        margin: calc(var(--spacing) * 3) 0;
        h2 {
          ${Rems({ type: "font-size", size: 30 })};
          font-family: var(--font-extra-light);
          font-weight: 100;
          margin-bottom: 0;
        }
      }
      &:nth-of-type(3) {
        p {
          padding-top: calc(var(--spacing) * 1);
        }
      }
    }
  }

  .right {
    .item {
      position: relative;

      &:nth-of-type(2) {
        margin: calc(var(--spacing) * 5) 0;
        p.upper-text {
          display: flex;
          flex-flow: column nowrap;
          font-family: var(--font-light);
          line-height: 1.3;
        }
        p.lower-text {
          ${Rems({type: "font-size", size: 46})};
          line-height: 1;
          font-family: var(--font-thin);
          
        }

        span {

          ${Rems({ type: "font-size", size: 32 })}
        }
      }
      &:nth-of-type(3) {
        h2 {
          letter-spacing: 10px;
          text-transform: uppercase;
          font-weight: 100;
          font-family: var(--font-extra-light);
          margin-bottom: 0;
        }
      }
    }
  }

  .item .upper-text {
    opacity: 0;
    transform: translateY(60px);
  }
  .item .lower-text {
    opacity: 0;
  }

  .lineLeft,
  .lineRight {
    width: 100%;
    position: absolute;
  }
  .lineLeft {
    right: 0;
  }
  .lineRight {
    left: 0;
  }

  .parallax-outer {
    height: 100%;
    z-index: -1;
    width: 100%;
    position: absolute;
    .parallax-inner {
      position: relative;
      height: 100%;
    }
  }

  .parallax {
    position: absolute;
    font-family: var(--font-thin);
    text-transform: lowercase;
    opacity: 0.2;
    letter-spacing: 4px;
    font-weight: unset;

    &.upgrade {
      bottom: -5%;
      right: 20%;
      max-width: 350px;
      ${Rems({ type: "font-size", size: 42 })};
    }
  }

  @media screen and (min-width: 768px) {
    padding: calc(var(--spacing) * 3) 0 175px;
    .grid {
      grid-template-columns: repeat(3, 1fr);

      svg#microGeo {
        margin-top: 150px;
      }
    }

    .dash:nth-of-type(2) {
      height: 450px;
      overflow: hidden;
    }

    .left {
      .item:first-of-type,
      .item:nth-of-type(2),
      .item:last-of-type {
        svg {
          width: 115%;
          margin-right: -15%;
        }
      }
      .item:nth-of-type(2) {
        margin: calc(var(--spacing) * 6) 0 calc(var(--spacing) * 9);
      }
    }

    .right {
      .item:nth-of-type(2) {
        margin: calc(var(--spacing) * 7) 0;
        span.sizes {
          ${Rems({ type: "font-size", size: 36 })};
        }
      }

      .item:first-of-type,
      .item:nth-of-type(2),
      .item:last-of-type {
        svg {
          width: 120%;
          margin-left: -20%;
        }
      }
    }
  }

  @media screen and (min-width: 1024px) {
    .right {
      .item:first-of-type p {
        padding-left: var(--spacing);
      }
      .item:nth-of-type(2) p {
        padding-left: var(--spacing);
      }
      .item:nth-of-type(3) {
        h2,
        p {
          padding-left: var(--spacing);
        }
      }
    }
    .parallax.upgrade {
      right: 14%;
    }
  }
`
