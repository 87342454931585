import React, { useEffect } from "react"
import { EntryStyles } from "./EntrySection.styles"

import Phone from "../../svgs/mobile-phone.svg"
import Maze from "../../svgs/maze.svg"

import { Parallax } from "react-scroll-parallax"

import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

gsap.registerPlugin(ScrollTrigger)

const EntrySection = () => {
  useEffect(() => {
    ScrollTrigger.batch(".grid-item.text p", {
      start: "100px bottom",
      onEnter: (elements, element, triggers) => {
        const tl = gsap.timeline()
        tl.to(elements, {
          opacity: 1,
          y: 0,
          duration: 1.5,
          stagger: 1,
        })
      },
    })

    ScrollTrigger.batch(".signal", {
      start: "0px bottom",
      onEnter: (elements, element, trigger) => {
        const tl = gsap.timeline()
        tl.to(elements, {
          opacity: 1,
          y: 0,
          duration: 1.5,
          stagger: 1.5,
          repeat: -1,
        })
      }
    })
  }, [])
  return (
    <EntryStyles>
      <div className="container">
        <Parallax y={[20, -25]}>
          <span className="parallax leo">Leo</span>
        </Parallax>
        <Parallax y={[10, -15]}>
          <span className="parallax geo">Geo</span>
        </Parallax>
        <Parallax y={[10, -10]}>
          <span className="parallax next-gen">next-gen</span>
        </Parallax>
        <div className="grid">
          <div className="grid-item text left">
            <p>
              <span>In the modern world, technology moves fast.</span> And those
              who don't adapt are left behind. Especially when it comes to
              aviation and maritime connectivity.
            </p>
          </div>
          <div className="grid-item image">
            <Phone className="phone" />
          </div>
        </div>
        <div className="grid">
          <div className="grid-item text right">
            <p>
              From GEO to MEO and LEO, multi-decade contracts, and the
              introduction of next-generation technology, the options can seem
              complex and overwhelming.
            </p>
          </div>
          <div className="grid-item image maze">
            <Maze className="maze" />
          </div>
        </div>
        <p className="lower-text">
        Decisions made today will influence your business model for years to come, leaving your company’s offerings always a step behind what passengers demand.
        </p>
      </div>
    </EntryStyles>
  )
}

export default EntrySection
