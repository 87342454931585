import React, { useEffect } from "react"
import { AstranisStyles } from "./AstranisItem.styles"
import MicroGeo from "../../../svgs/microgeo.svg"
import DashedLine from "../../UI/DashedLine"
import { Parallax } from "react-scroll-parallax"

import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

import UnderlineLeft from "../../../svgs/underline-left.svg"
import UnderlineRight from "../../../svgs/underline-right.svg"

gsap.registerPlugin(ScrollTrigger)

const AstranisItem = () => {
  useEffect(() => {
    ScrollTrigger.batch(".upper-text", {
      start: "100px bottom",
      onEnter: (elements, element, triggers) => {
        const tl = gsap.timeline()
        tl.to(elements, {
          opacity: 1,
          y: 0,
          duration: 1,
          stagger: {
            each: .5,
            onComplete() {
              gsap.fromTo(
                this._targets[0].parentNode.lastChild,
                {
                  opacity: 0,
                },
                { opacity: 1, duration: 2 }
              )
            },
          },
        })
      },
    })
  }, [])

  return (
    <AstranisStyles>
      <DashedLine height="150" length="150" v="450" m="150" />
      <div className="grid">
        <div className="left">
          <div className="item">
            <h2 className="upper-text">
              <span className="number">2024</span>
              Launch
            </h2>
            <UnderlineLeft />
            <div></div>
          </div>
          <div className="item">
            <h2 className="upper-text">18 months build</h2>
            <UnderlineLeft />
            <p className="lower-text">
              Approximately 18 months to build and launch, orbiting for seven
              years.
            </p>
          </div>
          <div className="item">
            <div className="upper-text"></div>
            <UnderlineLeft />
            <p className="lower-text">
              Ability to upgrade from the ground and move beams based on demand.
            </p>
          </div>
        </div>
        <MicroGeo />
        <div className="right">
          <div className="item">
            <p className="upper-text">Astranis founded 2015</p>
            <UnderlineRight />
            <div></div>
          </div>
          <div className="item">
            <p className="upper-text">
              Small, compact and versatile
              <span className="sizes">1m x 1m x 1m</span>
            </p>
            <UnderlineRight />
            <p className="lower-text">400kg</p>
          </div>
          <div className="item">
            <h2 className="upper-text">Flexible</h2>
            <UnderlineRight />
            <p className="lower-text">
              Less launch risk and quick lifecycle that doesn't lock you in for
              decades.
            </p>
          </div>
        </div>
      </div>
      <Parallax y={[20, -25]}>
        <span className="parallax upgrade">upgrade from the ground</span>
      </Parallax>
      <DashedLine height="400" length="450" v="1000" m="150" />
    </AstranisStyles>
  )
}

export default AstranisItem
