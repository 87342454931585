import React, { useEffect, useRef } from "react"
import SwiperComponent from "../SwiperComponent/SwiperComponent"

import CountUp from "react-countup"
import { useInView } from "react-intersection-observer"

import { ConnectivityStyles } from "./ConnectivitySection.styles"
import NeonLine from "../../svgs/neon-line.svg"
import { gsap } from "gsap"
import { ScrollTrigger, CSSPlugin } from "gsap/all"

gsap.registerPlugin(ScrollTrigger, CSSPlugin)

const ConnectivitySection = () => {
  const container = useRef()
  useEffect(() => {
    gsap.fromTo(
      ".welcome h2",
      {
        opacity: 0,
        filter: "blur(20px) drop-shadow(0px 0px 6px rgba(229,194,181, 1))",
      },
      {
        y: 0,
        duration: 1.5,
        opacity: 1,
        stagger: 1,
        filter: "blur(0px) drop-shadow(0px 0px 6px rgba(229,194,181, 0.4))",
        scrollTrigger: {
          trigger: ".welcome",
          start: "+=350 bottom",
        },
      }
    )

    gsap.fromTo(
      ".connectivity-underline #lineNeon",
      {
        duration: 8,
        attr: {
          d: "M -100 0 l 180 0",
        },
      },
      {
        attr: {
          d: "M 1000 0 l 180 0",
        },
        scrollTrigger: {
          trigger: container.current,
          scrub: true,
          start: "0px bottom",
          toggleActions: "restart none none none",
        },
      }
    )

    gsap.fromTo(
      ".anuvu-underline #lineNeon",
      {
        duration: 12,
        attr: {
          d: "M 950 0 l 180 0",
        },
      },
      {
        attr: {
          d: "M 0 0 l 180 0",
        },
        scrollTrigger: {
          trigger: container.current,
          scrub: true,
          start: "+=200px bottom",
        },
      }
    )
  }, [])

  const COUNT_UP_START = 0

  const { ref, inView, entry } = useInView({
    threshold: 0,
    triggerOnce: true,
  })

  return (
    <ConnectivityStyles>
      <div className="container" ref={container}>
        <div className="title-container header">
          <h2>
            Anuvu is bringing a fresh approach to the business of satellite
            connectivity.
          </h2>
          <NeonLine className="connectivity-underline" />
        </div>
        <div className="text-container">
          <div className="left">
            <p>
              Leveraging cutting-edge technology, to give you flexible solutions
              so you're <strong>not locked in for decades</strong>,{" "}
              <strong>stuck</strong> with legacy systems and{" "}
              <strong>tired</strong> business models.
            </p>
          </div>
          <div className="right" ref={ref}>
            <h3>
              <CountUp
                start={inView ? COUNT_UP_START : 40}
                end={40}
                delay={0}
                duration={5}
              >
                {({ countUpRef }) => (
                  <span className="number" ref={countUpRef} />
                )}
              </CountUp>
              Years
            </h3>
          </div>
        </div>
        <div className="title-container welcome">
          <h2>
            <span>Welcome to</span> The Anuvu Constellation
          </h2>
          <NeonLine className="anuvu-underline" />
          <p>
            Built by technology company Astranis, our
            constellation will provide smart, efficient and agile connectivity
            to those in the air and at sea.
          </p>
        </div>

        <div className="table-container">
          <SwiperComponent />
        </div>

        <p className="center">
          Providing dedicated coverage and capacity tailored to terminals on the
          move you'll get the connectivity you need now - with the ability to
          adapt in the future.
        </p>
      </div>
    </ConnectivityStyles>
  )
}

export default ConnectivitySection
