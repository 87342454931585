import styled from "styled-components"
import Rems from "../../styles/mixins/Rems"

export const FooterStyles = styled.footer`
  padding: calc(var(--spacing) * 6) 0 calc(var(--spacing) * 5);
  background: linear-gradient(
    220deg,
    #253542 0,
    #243b55 95%,
    #192129 95%,
    #39607d 97%,
    #192129 97%,
    #172337 98%,
    #192129 98%
  );
  z-index: 0;
  position: relative;
  &:after,
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  &:after {
    background: linear-gradient(
      140deg,
      rgba(62, 115, 155, 0.9) 0,
      rgba(107, 149, 177, 0) 30%,
      rgba(246, 246, 246, 0) 100%
    );
    z-index: -1;
  }

  &:before {
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.9) 0,
      rgba(0, 0, 0, 0) 30%,
      rgba(246, 246, 246, 0) 100%
    );
    z-index: -1;
  }

  .form-container,
  .bottom-container {
    max-width: var(--content-width);
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .form-container {
    z-index: 10;
    h2 {
      color: var(--neon);
      margin: 0;
    }
    form {
      display: grid;
      gap: var(--spacing);
    }
    input[type="text"],
    input[type="email"],
    input[type="tel"] {
      width: 100%;
      font-size: 16px;
      padding: calc(var(--spacing) / 2) calc(var(--spacing) * 1);
    }
    input[type="checkbox"] {
      transform: scale(1.1);
    }
    .cb-container {
      max-width: 500px;
      line-height: 1.2;
      color: var(--white);
      font-family: var(--font-light);
      .checkbox-wrap {
        display: flex;
        align-items: center;
        flex-flow: row nowrap;
        gap: calc(var(--spacing) / 1.5);
      }
      label {
        ${Rems({ type: "font-size", size: 12 })};
      }
    }
    .input-group {
      display: grid;
      input {
        order: 2;
      }
      label {
        color: var(--white);
        font-family: var(--font-light);
        ${Rems({ type: "font-size", size: 14 })};
      }
    }
  }

  .shadow-hr {
    max-width: 1200px;
    height: 10px;
    position: relative;
    display: block;
    margin: calc(var(--spacing) * 5) auto calc(var(--spacing) * 5);
    &:after {
      content: "";
      display: block;
      position: absolute;
      bottom: -1rem;
      background: radial-gradient(
        farthest-corner at 50% 0,
        rgba(0, 0, 0, 0.6) 0,
        transparent 50%
      );
      width: 100%;
      height: 1rem;
    }
  }

  .bottom-container {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    flex-flow: row wrap;
  }

  .links-col {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-flow: row wrap;
    gap: calc(var(--spacing) * 2) calc(var(--spacing) * 3);
  }

  .item {
    display: flex;
    align-items: flex-start;
    flex-flow: column nowrap;
    color: var(--white);
    p {
      margin: 0;
      font-family: var(--font-light);
      ${Rems({ type: "font-size", size: 12 })};
    }
  }

  a {
    color: var(--white);
    text-decoration: none;
    font-family: var(--font-light);
    ${Rems({ type: "font-size", size: 12 })};
  }

  .socials {
    .social-icons {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: calc(var(--spacing) / 2);
      a {
        ${Rems({ type: "font-size", size: 20 })};
      }
    }
  }

  p.success-message {
    margin: 0;
    color: var(--neon);
  }

  .visit-anuvu {
    flex-flow: column nowrap;
    justify-self: flex-end;
    justify-content: space-between;
    align-items: flex-end;
    a {
      color: var(--neon);
      display: flex;
      align-items: center;
      justify-content: center;
      gap: var(--spacing);
      ${Rems({ type: "font-size", size: 24 })};
      span {
        border-radius: 50%;
        border: 1px solid var(--neon);
        box-shadow: 0 0px 5px 1px var(--neon);
        background: var(--navy);
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      svg {
        margin-left: 1px;
        ${Rems({ type: "font-size", size: 20 })};
      }
    }
  }

  .legal {
    ${Rems({ type: "font-size", size: 10 })};
    font-family: var(--font-light);
  }
`
