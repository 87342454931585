import React from "react"

const Input = props => {
  let inputElement = null
  switch (props.elementType) {
    case "input":
      inputElement = (
        <div className="input-group">
          <input
            {...props.elementConfig}
            value={props.value}
            id={props.elementConfig.name}
            onChange={props.changed}
          />
          <label
            className="form-input-label"
            htmlFor={props.elementConfig.name}
          >
            {props.elementConfig.label}
          </label>
        </div>
      )
      break
    case "textarea":
      inputElement = (
        <div className="input-group">
          <textarea
            {...props.elementConfig}
            id={props.elementConfig.name}
            value={props.value}
            onChange={props.changed}
          />
          <label
            className="form-input-label"
            htmlFor={props.elementConfig.name}
          >
            {props.elementConfig.label}
          </label>
        </div>
      )
      break
    case "checkbox":
      inputElement = (
        <div className="cb-container">
          {props.title ? <h3>{props.title}</h3> : null}
          <div className="checkbox-wrap">
            <input
              {...props.elementConfig}
              value={props.value}
              id={props.elementConfig.name}
              onChange={props.changed}
            />
            <label className="cb-label" htmlFor={props.elementConfig.name}>
              {props.elementConfig.label}
            </label>
          </div>
        </div>
      )
      break
    case "radio":
      inputElement = (
        <div className="cb-container">
          {props.title ? <h3>{props.title}</h3> : null}
          <div className="checkbox-wrap">
            <input
              {...props.elementConfig}
              value={props.value}
              id={props.elementConfig.name}
              onChange={props.changed}
            />
            <label className="cb-label" htmlFor={props.elementConfig.name}>
              {props.elementConfig.name}
            </label>
          </div>
        </div>
      )
      break
    default:
      inputElement = (
        <input
          {...props.elementConfig}
          value={props.value}
          id={props.elementConfig.name}
          onChange={props.changed}
        />
      )
  }
  return inputElement
}

export default Input
