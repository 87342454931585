import styled, { css, keyframes } from "styled-components"
import Rems from "../../styles/mixins/Rems"

const MazeKeyframes = keyframes`
  0% {
    opacity: 0.6;
  }
  20% { 
    opacity: 0.3;
  }

  40% {
    opacity: 1;
  }
  50% {
    opacity: 0.6
  }
  100% {
    opacity: 0.6
  }
`

const NokiaKeyframes = keyframes`
  0% {
    opacity: 0.6;
  }
  90% {
    opacity: 0.1;
  }
  100% {
    opacity: 0.6;
  }
`

export const EntryStyles = styled.section`
  path.sat,
  g.sat {
    animation: ${MazeKeyframes} 8s ease-in-out infinite;
  }

  .fade {
    animation: ${NokiaKeyframes} 5.5s ease-in-out infinite;
  }

  .signal {
    opacity: 0;
  }


  --overlap-ratio:2;
  --overlap: max( 100px, 75% );
 
  z-index: 12;
  height: 100%;
  margin-top: calc( -1 * var(--overlap-ratio,1) * var( --overlap) );
  padding: var(--overlap) 0 100px;
  color: white;
  position: relative;

  background: linear-gradient( 180deg,
    rgba(0,0,0,0) 0%,
    rgba(35,49,60,0.2) 5%,
    rgba(35,49,60,0.7) 10%,
    rgba(35,49,60,1) 12%,
    rgba(35,49,60,1) 85%,
    rgba(35,49,60,0.8) 90%,
    rgba(35,49,60,0.4) 95%,
    rgba(0,0,0,0) 100% );

  
    

  .container {
    width: 90%;
    max-width: var(--content-width);
    margin-left: auto;
    margin-right: auto;
    position: relative;
    display: grid;
    gap: calc(var(--spacing) * 3);
  }

  .lower-text {
    font-family: var(--font-titles);
    ${Rems({ type: "font-size", size: 20 })};
    margin-top: calc(var(--spacing) * 4);
  }

  .grid {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: row wrap;
    width: 100%;

    .grid-item {
      &.image {
        margin-top: calc(var(--spacing) * 3);
        margin-bottom: calc(var(--spacing) * 3);
      }
      &.text {
        display: flex;
        align-items: center;
        position: relative;
        span {
          font-weight: bolder;
        }
        &:before,
        &:after {
          content: "";
          position: absolute;
          bottom: 0;
          height: 1px;
          line-height: 1;
        }
        p {
          width: 100%;
          padding-bottom: calc(var(--spacing) * 2);
          margin-bottom: 0;
          transform: translateY(60px);
          opacity: 0;
        }
        &.left {
          &:before {
            width: 100%;
            right: 0;
            background: rgba(255, 255, 255, 0.3);
          }
          &:after {
            width: 40%;
            min-width: 125px;
            max-width: 250px;
            left: 0;
            background: rgba(255, 255, 255, 1);
          }
        }

        &.right {
          &:before {
            width: 100%;
            left: 0;
            background: rgba(255, 255, 255, 0.3);
          }
          &:after {
            width: 30%;
            min-width: 125px;
            max-width: 250px;
            right: 0;
            background: rgba(255, 255, 255, 1);
          }
          p {
            margin-left: auto;
          }
        }
      }
    }
  }

  svg {
    max-width: 300px;
    width: 120%;
    height: 100%;
    &.phone {
      max-width: 300px;
    }
  }

  .parallax-outer {
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: -1;
    .parallax-inner {
      position: relative;
      height: 100%;
    }
  }
  .parallax {
    position: absolute;
    font-family: var(--font-thin);
    opacity: 0.1;
    letter-spacing: 4px;
    font-weight: unset;

    &.leo {
      bottom: 100px;
      left: 10%;
      text-transform: uppercase;
      ${Rems({ type: "font-size", size: 40 })};
    }

    &.geo {
      bottom: 150px;
      right: 15%;
      text-transform: uppercase;
      ${Rems({ type: "font-size", size: 26 })};
    }

    &.next-gen {
      bottom: 600px;
      right: 10%;
      ${Rems({ type: "font-size", size: 26 })};
    }
  }

  @media screen and (min-width: 250px) {
    --overlap-ratio:1.75;
  }

  @media screen and (min-width: 320px) {
    --overlap-ratio:1.5;
  }

  @media screen and (min-width: 440px) {
    --overlap-ratio:1.33;
    --overlap: max( 120px,64% );
    background: linear-gradient( 180deg,
      rgba(35,49,60,0) 7%,
      rgba(35,49,60,0.2) 10%,
      rgba(35,49,60,0.7) 13%,
      rgba(35,49,60,1) 15%,
      rgba(35,49,60,1) 85%,
      rgba(35,49,60,0.8) 90%,
      rgba(35,49,60,0.4) 95%,
      rgba(35,49,60,0) 100% );
  }

  @media screen and (min-width: 768px) {
    --overlap-ratio:1.2;
    --overlap:max( 330px,33% );
    padding-bottom: 0;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 3%,
      rgba(35, 49, 60, 0.2) 10%,
      rgba(35, 49, 60, 1) 25%,
      rgba(35, 49, 60, 1) 76%,
      rgba(35, 49, 60, 0.7) 85%,
      rgba(35, 49, 60, 0.4) 93%,
      rgba(0, 0, 0, 0) 100%
    );
    .grid {
      flex-flow: row nowrap;
      justify-content: space-between;
      gap: 0;
      .grid-item {
        width: 100%;
        &.text p {
          max-width: 450px;
        }
        &.right {
          order: 2;
          &.text:before {
            width: 110%;
            margin-left: -10%;
          }
        }
        &.left.text:before {
          width: 110%;
          margin-right: -10%;
        }
        &.image:not(.maze) {
          display: flex;
          justify-content: flex-end;
        }
        &.image {
          margin-top: unset;
          margin-bottom: unset;
        }
      }
    }

    .parallax {
      &.leo {
        left: unset;
        right: 35%;
        ${Rems({ type: "font-size", size: 86 })};
      }
      &.geo {
        right: 5%;
        bottom: 175px;
        ${Rems({ type: "font-size", size: 56 })};
      }
      &.next-gen {
        bottom: 500px;
        right: 0%;
        ${Rems({ type: "font-size", size: 46 })};
      }
    }
  }

 
    
  @media screen and (min-width: 1024px) {
    --overlap-ratio:1.1;
    margin-top: -500px;
    padding-top: 420px;
    padding-bottom: 0;
   
  background: linear-gradient(
    180deg,
    rgba(35, 49, 60, 0) 0%,
    rgba(35, 49, 60, 0.1) 5%,
    rgba(35, 49, 60, 0.3) 12%,
    rgba(35, 49, 60, 1) 25%,
    rgba(35, 49, 60, 1) 75%,
    rgba(35, 49, 60, 0.7) 86%,
    rgba(35, 49, 60, 0.3) 93%,
    rgba(35, 49, 60, 0) 100%
  );
          
    // background: linear-gradient(
    //   180deg,
    //   rgba(0, 0, 0, 0) 0%,
    //   rgba(35, 49, 60, 0.2) 0%,
    //   rgba(35, 49, 60, 0.7) 0%,
    //   rgba(35, 49, 60, 1) 5%,
    //   rgba(35, 49, 60, 1) 25%,
    //   rgba(35, 49, 60, 0.8) 60%,
    //   rgba(35, 49, 60, 0.4) 85%,
    //   rgba(0, 0, 0, 0) 100%
    // );
    .lower-text {
      ${Rems({ type: "font-size", size: 24 })};
    }
    .grid-item.right.text {
      margin-top: -40px;
      padding-left: 50px;
    }

    svg.maze {
      margin-left: -5%;
    }
    svg {
      max-width: 450px;
      width: 120%;

      &.phone {
        max-width: 400px;
      }
    }

    .parallax {
      &.geo {
        right: -11%;
        bottom: 155px;
      }
      &.next-gen {
        ${Rems({ type: "font-size", size: 54 })};
        right: -21%;
      }
      &.leo {
        ${Rems({ type: "font-size", size: 100 })};
        bottom: 50px;
        right: 30%;
      }
    }
  }
`
