import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { MovingForwardsStyles } from "./MovingFowardsStyles.styles"

import Wire from "../../svgs/wiring.svg"

const MovingForwards = () => {
  return (
    <MovingForwardsStyles>
      <StaticImage
        src="../../images/sea-sunset.png"
        alt="sunset"
        objectFit="cover"
      />
      <Wire />
    </MovingForwardsStyles>
  )
}

export default MovingForwards
