import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import EntrySection from "../components/EntrySection/EntrySection"
import PlaneSection from "../components/PlaneSection/PlaneSection"
import ConnectivitySection from "../components/ConnectivitySection/ConnectivitySection"
import MovingForwards from "../components/MovingForwards/MovingForwards"
import TimelineSection from "../components/TimelineSection/TimelineSection"
import PostsSection from "../components/PostsSection/PostsSection"
import TelesatSection from "../components/TelesatSection/TelesatSection"
import {ParallaxProvider} from 'react-scroll-parallax'

const IndexPage = () => (
  <ParallaxProvider>
    <Layout>
      <Seo title="Home" />
      <EntrySection />
      <PlaneSection />
      <ConnectivitySection />
      <MovingForwards />
      <TimelineSection />
      <TelesatSection />
      <PostsSection />
    </Layout>
  </ParallaxProvider>
)

export default IndexPage
