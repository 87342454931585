import React from "react"
import styled, { keyframes } from "styled-components"

const pulse = keyframes`
  0% {
    opacity: .3;
    filter: drop-shadow(0 0 1px rgba(255, 255, 255, 0.3));
  }
  50% {
    opacity: .7;
    filter: drop-shadow(0 0 5px rgba(255, 255, 255, 0.8));
  }
  100% {
    opacity: .3;
    filter: drop-shadow(0 0 1px rgba(255, 255, 255, 0.3));
  }
`

const DashlineAnim = styled.svg`
  animation: ${pulse} 4s ease-in-out infinite;
  transition: all 4s ease-in-out;
`

const DashedLine = ({ height, length, v, m }) => (
  <DashlineAnim
    height={height}
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    className="dash"
  >
    <path
      d={`M${m},${length} v-${v}`}
      fill="white"
      stroke="white"
      strokeWidth="2"
      strokeDasharray="8"
    />
  </DashlineAnim>
)

export default DashedLine
