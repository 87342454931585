import styled from "styled-components"

export const MovingForwardsStyles = styled.section`
  color: blue;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  .gatsby-image-wrapper {
    max-height: 500px;
    min-height: 300px;
    z-index: 2;
    position: relative;

    &::after {
      background: linear-gradient(180deg,rgba(204,179,174,.5) 0%, rgba(204,179,174,.2) 100%);
      top: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      content: '';
      
    }
  }

  svg {
    position: absolute;
    z-index: 30;
    top: -15%;
    left: 50%;
    transform: translateX(-50%);
    width: 110%;
    margin-left: auto;
    max-width: 1800px;
    min-width: 575px;
  }

  @media screen and (min-width: 768px) {
    svg {
      top: -20%;
    }
  }

  @media screen and (min-width: 1024px) {
    svg {
      top: -30%;
    }
  }
`
