import styled from "styled-components"

export const StatusTimelineSectionStyles = styled.section`
    
    background:url('/images/StatusTimelineSection.screenshot.png');
    color:var(--white, #fff);
    text-transform:uppercase;
    position:relative;
  
    max-width:100%;
    overflow:auto;

    ul{
        // size of the circle
        --size:4;
        // space between circle and text
        --gap:50px;
        // amount of items in the bar
        --entries:5;
        --border:2;
        --active:2;
        // size of the small dot
        --scale:4;
        // space between dashes
        --dash:66%;
        // the dash!
        --dot:6px;
        // allow the font to grow but never shrink below
        --min-font-size:17%;
        --max-font-size:100%;

        // left margin for lines in timeline
        --_e:calc(100% / ( var(--entries) * 2 ));
        --_h:calc(var(--size) * 1px * var(--scale) );

        list-style:none;
        display:flex;
        align-items:center;
        justify-content:stretch;
        text-transform:inherit;
        position:relative;
    }

    // line that draws from dashed to filled
    ul::before,
    ul::after{
        --border:1;
        display:block;
        content:'';
        height:calc(var(--border)  * 1px);
        width:calc( var(--_e) * (var(--entries) * 2 - 2 ) );

        position:absolute;
        z-index:-1;
        top:calc( (var(--_h) / 2) - ( (var(--border) * 1px )/ 2 ));
        
        left:var(--_e);
        right:var(--_e);        
        // margin-left:var(--_e);
        // margin-right:var(--_e);
        
        box-sizing:border-box;
        
        // for dashes
        background-color:transparent;
        background-image: linear-gradient(90deg, var(--white, #fff), var(--white, #fff) var(--dash), transparent var(--dash), transparent 100%);
        background-size: var(--dot) calc(var(--border)  * 1px);
    }

    // dashed line
    ul::before{
        opacity:0.45;
    }

    // solid overlaid line
    ul::after{
        --border:2;
        // for solid
        --_g:calc(100% / var(--entries));
        --_w:calc( var(--_g) * var(--active) );
        transition:max-width .4s ease-out;
        max-width:var(--_w);
        background-color:var(--white, #fff);
        z-index:2;
    }
    li{
        --strength: 0.8;
        --distance:2px;
        --blur:rgba(88, 249, 244, var(--strength));

        --shadow:   1px 1px var(--distance,5px) var(--blur),
                    1px -1px var(--distance,5px) var(--blur),
                    -1px 1px var(--distance,5px) var(--blur),
                    -1px -1px var(--distance,5px) var(--blur);


        --font-zoom:0;
        --font-range:calc( var(--max-font-size, 100%) - var(--min-font-size) );
        --font-scale:calc( var(--font-zoom,0) / 100 * var(--font-range));
        --font-size:calc( var(--font-scale,0%) + var(--min-font-size));
        
        font-size:var(--font-size);
        font-size:clamp( var(--min-font-size), 2.5vw ,var(--font-size,0%) );

        flex: 1;

        display:grid;
        justify-items: center;
        text-transform: inherit;
        word-break: keep-all;
        line-height:1.3;
        
        //align-items: center;
        grid-template-rows:calc(var(--size) * 1.5px) var(--gap) 5rem;
        text-transform:inherit;
        // align-items: end;
        align-items: baseline;

        // background: linear-gradient(var(--white, #fff),var(--white, #fff)) center / 100%  2px, transparent;
        // for solid line
        // background-repeat:no-repeat;

        -webkit-text-shadow: 1px 1px 5px rgba(1, 40, 39, 0.9);
        text-shadow: 1px 1px 5px rgba(1, 40, 39, 0.9);
    }

    li:first-of-type{
        
    }

    li:last-of-type{
        // outline:2px solid red;
        // white-space:normal;
    }

    li::before{
        align-self: start;
        content:'';
        display:block;
        width:calc(var(--size) * 1px);
        height:calc(var(--size) * 1px);
        border-radius:99px;
        // grid-area:1/1/-1/-1;
        background-color:var(--white, #fff);
        position:relative;
        z-index:2;
    }
    li::after{
        align-self: start;
        content:'';
        order:-1;
        visibility:hidden;
        box-sizing:border-box;
        display:block;
        width:var(--_h);
        height:var(--_h);
        transform-origin:center;
        border-radius:99px;
        border: calc(var(--border)  * 1px) solid var(--neon);
        // grid-area:1/-1;
        background-color:var(--dark-navy);
        box-shadow: 0 0px 6px .5px var(--neon);
        z-index:1;
    }

    // blue neon : #60f0eb
    li.active{
       
        color:var(--neon, #60f0eb );
        text-shadow:var(--shadow);
        z-index:3;
    }

    li.active::after{
        visibility:visible;
        animation:RevealActiveTimeLineItem 3500ms cubic-bezier(0.175, 0.885, 0.32, 1.275) both 0s;
    }
    
    li.active::before{
        background-color:var(--neon);
        box-shadow: 0 0px 6px .5px var(--neon);
    }

    meter{
        height:0;
        width:100%;
        overflow:hidden;
        -webkit-appearance:none;
        appearance:none;
        position:absolute;
        left:0;//var(--_e);
        bottom:var(--_e, 0);
        z-index:-1;
        color:transparent;
        visibility:hidden;
        opacity:0;
        user-select:none;
        pointer-events:none;
    }
    
@media screen and (min-width: 242px) {
    ul{
        --size:6;
        --dash:50%;
        --dot:12px;
    }
    li{
        --font-zoom:3;
        --strength: 0.7;
        --distance:3px;
    }
}
    
@media screen and (min-width: 282px) {
    ul{
        // size of the circle
        --size:7;
        --dash:40%;
        --dot:20px;
    }
    li{
        --strength: 0.6;
        --distance:4px;
        --font-zoom:10;
    }
}
    
@media screen and (min-width: 320px) {
    ul{
        --size:8;
    }
    li{
        --strength: 0.5;
        --distance:5px;
        --font-zoom:19;
    }
}
    
@media screen and (min-width: 360px) {
    ul{
        // size of the circle
        --size:9;
    }
    li{
        --font-zoom:30;
    }
}
    
@media screen and (min-width: 422px) {
    ul{
        --dash:35%;
        --dot:30px;
    }
  
    li{
        --font-zoom:42;
    }
}

@media screen and (min-width: 606px) {
    
    li{
        --font-zoom:66;
    }
}

@media screen and (min-width: 768px) {
    li{
        --font-zoom:80;
    }
}

@media screen and (min-width: 848px) {
    li{
        --font-zoom:90;
    }
}

@media screen and (min-width: 920px) {
    ul{
        --dash:50%;
        --dot:40px;
    }
    li{
        --font-zoom:100;
    }
}
`